import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { SessionService } from 'src/app/services/session-service.service';

@Component({
  selector: 'app-questions-preview',
  templateUrl: './questions-preview.component.html',
  styleUrls: ['./questions-preview.component.css']
})
export class QuestionsPreviewComponent implements OnInit, OnChanges {
  public SINGLE_SELECT = 'Single choice';
  public MULTI_SELECT = 'Select all that apply';
  public COMBINATION = 'Combination';
  public isError = false;
  public errorData: any;
  public atmostEnable = undefined;
  public atleastEnable = undefined;
  public chooseEnable = undefined;
  @Input() isLiveMessage: boolean;
  @Input() messageQuestions: any;
  @Input() popUpContent: any;
  @Input() isVisible: any;
  @Input() questionDetails: any;
  @Input() errorMessage: any;
  @Input() messageScreenText: any;
  @Output('onDataEmit') dataToParent = new EventEmitter();
  @Output('onDataToLiveSurvey') dataToQuestionPreview = new EventEmitter();
  @Output('isOkOrCancel') isOkOrCancel = new EventEmitter();
  clickedInsideThePage = false;
  messageQuestionIndex: any;
  messageQuestionTotalCount: any;
  messageQuestionBreakCount: any;
  constructor(private _session: SessionService) {

  }

  ngOnChanges(changes: SimpleChanges) {
    setTimeout(() => {
      this.atmostEnable = this._session.api.local.get('ATMOSTENABLE');
      this.atleastEnable = this._session.api.local.get('ATLEASTENABLE');
      this.chooseEnable = this._session.api.local.get('CHOOSEENABLE');
      // console.log('atmostEnable', this.atmostEnable);
      // console.log('atleastEnable', this.atleastEnable);
      // console.log('chooseEnable', this.chooseEnable);
    }, 100);
    console.log('questionType ==>', this.questionDetails.questionType);
    console.log('instruction[0]', this.questionDetails.Instructions[0]['instruction']);
    console.log('combi', this.questionDetails.combinationTypes);
    if (changes['errorMessage']) {
      this.isError = true;
      this.errorData = this.errorMessage;
    };
  }

  ngOnInit() {
    if (this.messageQuestions) {
      this.messageQuestionIndex = this.messageQuestions.current_message_index + 1;
      this.messageQuestionTotalCount = this.messageQuestions.total_message_count;
      this.messageQuestionBreakCount = this.messageQuestions.break_count && (this.messageQuestions.break_count == 0 || this.messageQuestions.break_count > this.messageQuestions.total_message_count) ? this.messageQuestions.total_message_count : this.messageQuestions.break_count;
      if (this.messageQuestionIndex > this.messageQuestionBreakCount && this.messageQuestionBreakCount < this.messageQuestionTotalCount) {
        this.messageQuestionIndex = this.messageQuestionIndex - this.messageQuestionBreakCount;
        this.messageQuestionBreakCount = this.messageQuestionTotalCount - this.messageQuestionBreakCount;
      }

    }

  }

  /**
   * If clicks anywhere in the page, sending a var to date-question-component to handle date picker
   */
  clickedInside() {
    this.clickedInsideThePage = this.clickedInsideThePage == true ? false : true;
  }

  emitDataToParent(e) {
    this.dataToParent.emit(e);
  }

  questionBasedError(message) {
    this.errorData = message;
  }

  /**
   * Emit data to parent
   * @param message 
   */
  emitDataToQuestionPreview(message) {
    this.dataToQuestionPreview.emit(message)
  }

  /**
   * Fetch ok or cancel from message-popup and emiting it to live-survey
   * @param message 
   */
  emitIsOkOrCancelToPreview(message) {
    this.isOkOrCancel.emit(message)
  }



}
