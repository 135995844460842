import { Component,Inject, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { SessionService } from '../../../services/session-service.service';
import { Config } from '../../../config/config';


@Component({
  selector: 'app-blank-table-question',
  templateUrl: './blank-table-question.component.html',
  styleUrls: ['./blank-table-question.component.css']
})
export class BlankTableQuestionComponent implements OnInit {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();

  constructor(
    private _session: SessionService,
    @Inject(DOCUMENT) private document: any

  ) { }

  ngOnInit() {
    const duration = this._session.api.local.get(Config.CONSTANTS.HOLD_SCREEN_DURATION);
    this.document.getElementById('nextBtn').disabled = true;
        setTimeout(function () {
          this.document.getElementById('nextBtn').disabled = false;
        }, duration * 1000);
    this.emitData.emit();
  }

}
