import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { HttpLayerService } from '../../../services/http-layer.service';
import { Config } from '../../../config/config';
import { SessionService } from '../../../services/session-service.service';
import { NzNotificationService } from 'ng-zorro-antd';

@Component({
  selector: "single-type-message",
  templateUrl: "./single-type-message.component.html",
  styleUrls: ["./single-type-message.component.css"],
})
export class SingleTypeMessageComponent implements OnInit {
  @Input() questionDetails: any;
  @Input() isLiveMessage: boolean;
  @Input() messageQuestions: any;
  @Input() messageScreenText: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public response      = [];
  LoopNo               = [];
  messages             = [];
  messageSet           = [];
  selectedRespondentNo = 1;
  selectedLoopNo       = 1;
  radioValue;
  savedData;
  private BASE_INPUT;

  constructor(private _commonServices: CommonServices, private _session: SessionService, private httpLayer: HttpLayerService, private notification: NzNotificationService) {
  }

  ngOnInit() {
    if(this.isLiveMessage){
      if (this.messageQuestions && this.messageQuestions.data.length > 0) {
        this.messages   = [];
        this.messageSet = this.messageQuestions.data;
        this.savedData  = this.messageQuestions.response && this.messageQuestions.response.length > 0 ? this.messageQuestions.response[0] -1 : null;
        this.savedData  = this.savedData !== null ? this.savedData.toString() : null;
        this.messageSet[0].forEach((item, index) => { this.messages.push([this.messageSet[0][index], this.messageSet[1][index]]) });
        this.createResponseJson(this.savedData);
      }
    }else{
      this.LoopNo         = this.questionDetails.message_options.data["0"].messageIndex;
      this.selectedLoopNo = this.LoopNo["0"];
      this.onUpdateLoopNo(1);
    }
  }

  /**
   * Triggered on udate of respondent number
   * @param value - value
   */
  onUpdateRespondentNo(value): void {
    this.selectedRespondentNo = value;
    this.LoopNo               = this.questionDetails.message_options.data[value - 1].messageIndex;
    this.onUpdateLoopNo(1);
  }

  /**
   * Triggered on udate of loop number
   * @param value - value
   */
  onUpdateLoopNo(value) {
    this.BASE_INPUT = JSON.stringify(
      this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT)
    );
    const inputData           = {};
    this.messages             = [];
    this.selectedLoopNo       = value;
    inputData['surveyKey']    = JSON.parse(this.BASE_INPUT)['surveyKey'];
    inputData['participant']  = this.selectedRespondentNo;
    inputData['sino']         = this.LoopNo[this.selectedLoopNo-1];
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.MESSAGE_QUESTIONS_PREVIEW, inputData).subscribe(
      previewResponse => {
        if (previewResponse.status === 'success') {
          const results = previewResponse.result.data;
          results[0].forEach((item,index)=>{this.messages.push([results[0][index], results[1][index]])});
        } else {
          this.notification.error('Error', previewResponse['result'] || 'Failed to get data, please try again later');
        }
      }
    );
  }

  /**
   * Emit back the response to live survey to get saved
   */
  dataEmitter() {
    this.emitData.emit(this.response);
  }

  /**
   * To create response json
   * @param event - event
   */
  createResponseJson(event) {
    this.radioValue = event;
    if (this.isLiveMessage && event !== null && event >= 0 ) {
      let selectedValue = [];
      this.messageSet[event].forEach((item, index) => { selectedValue.push(this.messageSet[event][index].messageID) });
      this.response = [];
      this.response.push({
        selected_value: event,
        selected_item: "First",
        selected_row: event,
        option_type: "Message_single_type",
        selected_header: "STORY " + event,
      })
      this.dataEmitter();
    }
  }
}

