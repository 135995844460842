import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { isNumeric } from 'rxjs/util/isNumeric';
import { SessionService } from '../../services/session-service.service';
import { Config } from '../../config/config';
import { R } from 'ng-zorro-antd';
// import {clone} from 'lodash';
// import {clonedeep} from 'lodash';
import { toInteger } from "chinese-numbers-to-arabic";
const ja2num = require('japanese-numerals-to-number');
import { num_ja } from "@hee-san/num_ja";
import toChineseNumeral from "to-chinese-numeral";
import { ArabicNumbers } from 'react-native-arabic-numbers';
import { HttpLayerService } from 'src/app/services/http-layer.service';


const lodashClonedeep = require('lodash.clonedeep');

@Injectable({
  providedIn: 'root'
})
export class QuestionsPreviewService {
  // public pattern = /((((COUNT|MIN|MAX|SUM|DURATION)\((\[[0-9a-zA-Z_]+\](,){0,}){1,}\))|\[[0-9a-zA-Z_]+\]).(=|<>|>|>=|<|<=).(((COUNT|MIN|MAX|SUM|DURATION)\((\[[0-9a-zA-Z_]+\](,){0,}){1,}\))|(ALL){0,}\[([0-9a-zA-Z_]+(,){0,}){1,}\]|[0-9a-zA-Z.%]+(.(MONTHS|DAYS|WEEKS|YEARS)){0,}|ANY)){1,}/i;
  public pattern = /(((((COUNT|MIN|MAX|SUM|DURATION)\(((\[[0-9a-zA-Z_]+\]|CURRENT_DATE)(,){0,}){1,}\))|\[[0-9a-zA-Z_]+\]).(=|<>|>|>=|<|<=).(((COUNT|MIN|MAX|SUM|DURATION)\((\[[0-9a-zA-Z_]+\](,){0,}){1,}\))|(ALL){0,}\[([0-9a-zA-Z_]+(,){0,}){1,}\]|[0-9a-zA-Z.%_\-" ]+(.(MONTHS|DAYS|WEEKS|YEARS)){0,}|ANY)(.(AND|OR).){0,}){1,}|([0-9a-zA-Z.%]+|\[[0-9a-zA-Z_]+\])(?:\s[+*/-]\s([0-9a-zA-Z.%]+|\[[0-9a-zA-Z_]+\])){1,}.(=|<>|>|>=|<|<=).(((COUNT|MIN|MAX|SUM|DURATION)\((\[[0-9a-zA-Z_]+\](,){0,}){1,}\))|\[([0-9a-zA-Z_]+(,){0,}){1,}\]|[0-9a-zA-Z.%]+))/i;
  public pipe_pattern = /((((COUNT|MIN|MAX|SUM|DURATION|OPTIONS|ATTRIBUTES)\(((([0-9a-zA-Z.%]+)|(\[[0-9a-zA-Z_]+\]))(,){0,}){1,}\)(.EXCEPT\((([0-9]+)(,){0,}){1,}\)){0,})|(([0-9a-zA-Z.%"]+)|(\[[0-9a-zA-Z_]+\])))(.(\+|-|\/|\*).){0,}){1,}/
  public operatorPattern = /<>|>=|>|<=|<|=/
  public arithmaticPattern = /\+|\-|\/|\*/g
  public itemPattern = /\[[0-9a-zA-Z_]+\]/
  public itemsPattern = /\[[0-9a-zA-Z_]+\]/g
  public valPattern = /[0-9a-zA-Z%_\- ]+/
  public multivalPattern = /(ALL){0,}\[[0-9,\s]+\]/
  public funPattern = /((COUNT|MIN|MAX|SUM|DURATION)\(((\[[0-9a-zA-Z_]+\]|CURRENT_DATE)(,){0,}){1,}\)){1,}/
  public multiItemsPattern = /\[[0-9a-zA-Z_]+\]/g
  public durationTypePattern = /MONTHS|DAYS|WEEKS|YEARS/
  public defaultDurationType = "months";
  public defaultStr = "DEFAULT";
  public secondaryDelimiter = "$$";
  public keyDelimiter = "_";
  public defaultHoldType = "SECOND";
  public holdTypeMinute = "MINUTE";
  public terminateSurvey = false;
  public terminateSurveyImmediately = false;
  public currentQuestion;
  public currentQuestionOptions;
  public currentSelectedData;
  public currentCompleteQuestion;
  public itemsMap = new Map();
  public askRulesMap = new Map();
  public skipRulesMap = new Map();
  public skipToRulesMap = new Map();
  public skipToRulePresent = false;
  public skipToQuestion;
  public questionType;
  public onScreenDisable;
  public formErrorMessage = false;
  // public errorMessage;
  public errorDetail;
  public onScreenRuleExecution = false;
  public surveyLanguage = 'en';
  public inputLangTranslationList = ['zh', 'ja', 'ar'];
  public arabicNumbers = [/٠/g, /١/g, /٢/g, /٣/g, /٤/g, /٥/g, /٦/g, /٧/g, /٨/g, /٩/g];
  public languageErrorNotification: any = {};
  public numberEachFieldRange;
  public currentQuestionDetailsSave: any = {
    section_index: '',
    current_question: ''
  };
  public pleaseSpecifyMatch = /(\(Please *specify\))/ig
  public respondentDetails: any = {};
  public currentVersionId;

  constructor(
    private _session: SessionService,
    private httpLayer: HttpLayerService,
  ) { }

  // handle rules within question text like - PIPE and LINK
  frameQuestion(data) {
    let questionData = data['question'];
    let questionTextRules = data['questionTextRules'];
    // let itemVal, pipeVar;
    // let items = questionData.match(this.itemsPattern);
    // if (items) {
    //   items.forEach((item) => {
    //     itemVal = this.getItemValue(item.substring(item.indexOf("[") + 1, item.indexOf("]")));
    //     questionData = questionData.replace(item, itemVal);
    //   });
    //   console.log("frameQuestion - question: " + questionData);
    //   return questionData;
    // }
    if (questionTextRules) {
      questionTextRules.forEach((rule) => {
        let ins = rule['instruction'];
        let parsedIns = rule['parsedInstruction'];
        if (ins && parsedIns) {
          // call rule -
          let result = this.ruleParser(parsedIns, null);
          console.log("frameQuestion - ins: " + ins + ", result: " + result);
          if (result || (typeof (result) === 'number' && result >= 0)) {
            questionData = questionData.replace(ins, result);
          }
        }
      });
    }
    console.log("frameQuestion - questionData: " + questionData);
    return questionData;
  }

  findAndReplaceVarWithData(expData) {
    let itemVal;
    let items = expData.match(this.itemsPattern);
    if (items) {
      items.forEach((item) => {
        itemVal = this.getItemValue(item.substring(item.indexOf("[") + 1, item.indexOf("]")));
        // if(itemVal) {
        expData = expData.replace(item, itemVal);
        // }
      });
      console.log("findAndReplaceVarWithData - expData: " + expData);
    }
    return expData;
  }

  findAndReplaceQuestionIdWithNumber(data) {
    let itemVal;
    let items = data.match(this.itemsPattern);
    if (items) {
      items.forEach((item) => {
        itemVal = this.getQuestionNumber(item.substring(item.indexOf("[") + 1, item.indexOf("]")));
        if (itemVal) {
          data = data.replace(item, itemVal);
        }
      });
      console.log("findAndReplaceQuestionIdWithNumber - data: " + data);
    }
    return data;
  }

  ruleParser(fun_string, errorDetail?, questDetails?) {
    try {
      console.log('ruleParser:: function: ' + fun_string + ", errorDetail: " + errorDetail);
      this.errorDetail = errorDetail;
      let flag;
      let fun_name = fun_string.substring(0, fun_string.indexOf("("));
      let fun_params = fun_string.substring(fun_string.indexOf("(") + 1, fun_string.length - 1);
      let params = fun_params.split("##");
      // console.log('fun_name:' + fun_name + ', params: ' + params);
      if (this[fun_name]) {
        flag = this[fun_name](params, errorDetail, questDetails);
      }
      return flag;
    } catch (err) {
      console.log('Error while executing rule: ' + err.message);
    }
  }
  
  /**
   * DO NOT FORCE Rule handling
   * @param params 
   * @param errorDetail 
   * @param questDetails 
   * @returns 
   */
  do_not_force_response(params?, errorDetail?, questDetails?){
    if(params[0] == 'column'){ /** Column level */
      let values = params[1].split(",");
      values.forEach(value => {
        this.currentSelectedData.forEach(element => {
          if(element.selected_header == value)
            element.doNotForce = true;
        });
      });
      return true;
    }else if(params[0] === 'field'){ /** Filed level */
      let values = params[1].split(",");
      values.forEach(value => {
        let test = value.split("_")
        this.currentSelectedData.forEach(element => {
          if(element.selected_header == test[1] && element.selected_row == test[2])
            element.doNotForce = true;
        });
      });
      return true;
    }else { /** Question level - Rating question will only work question level */
      this.currentSelectedData.forEach(element => {
          element.doNotForce = true;
      });
      return true;
    }
  }

  // Rule Functions - 

  page_rule(params) {
    let start = params[0], end = params[1];
    console.log("page_rule:: start question: " + start + ", end question: " + end);
    if (start && end) {
      console.log("page_rule:: TODO - call python service to get range of questions");
      this._session.api.local.save(Config.CONSTANTS.START_QID, start);
      this._session.api.local.save(Config.CONSTANTS.END_QID, end);
      return params;
      // TODO - call python service to get range of questions -
      // getRangeQuestions(start, end);
    }
  }

  terminate(params, error, question) {
    // if already marked for termination then do not execute -  
    // for now ignore as previous question may cause issue
    // if (!this.terminateSurvey) {
    let expression = params[0], type = params[1]
    console.log("terminate:: expression: " + expression + ", type: " + type);
    let terminate = this.parseExpression(expression);
    console.log('terminate:: status: ' + terminate);
    if (terminate) {
      this.terminateSurvey = true;
      localStorage.setItem('terminateSurveyQuestionDetails', JSON.stringify(question))
      if (terminate && type === "IMMEDIATELY") {
        this.terminateSurveyImmediately = true;
        return true;
      }
    }
    return false;
    // } 
    // else {
    //   console.log('Already marked for termination by on of the previous rule. Hence skipping current rule!')
    // }
    // return this.terminateSurvey;
  }

  range(params, errorMessage) {
    // let start = params[0], end = params[1];
    if (params[0] == '') {
      let start = this.findAndReplaceVarWithData(params[1]);
      let end = this.findAndReplaceVarWithData(params[2]);
      let evalStart = eval(start);
      let evalEnd = eval(end);
      /** Save range start and end values  in local storage */
      if (this.questionType == 'Number') {
        this._session.api.local.save(Config.CONSTANTS.RANGE_START, evalStart ? evalStart : start);
        this._session.api.local.save(Config.CONSTANTS.NUM_RANGE_END, evalEnd ? evalEnd : end);
      } else if (this.questionType == 'Percent') {
        this._session.api.local.save(Config.CONSTANTS.RANGE_START, evalStart ? evalStart : start);
        this._session.api.local.save(Config.CONSTANTS.PER_RANGE_END, evalEnd ? evalEnd : end);
      } else if (this.questionType == 'Date') {
        this._session.api.local.save(Config.CONSTANTS.DATE_START, start);
        this._session.api.local.save(Config.CONSTANTS.DATE_END, end);
        console.log('range :: rangeStart: ' + start + ', rangeEnd: ' + end);
      } else {
        this._session.api.local.save(Config.CONSTANTS.TEXT_START, evalStart ? evalStart : start);
        this._session.api.local.save(Config.CONSTANTS.TEXT_END, evalEnd ? evalEnd : end);
      }
    } else {
      let field = params[0].substring(params[0].indexOf("[") + 1, params[0].indexOf("]")), start = params[1], end = params[2], errorMsg = errorMessage;
      const answeredValue = this.getNumberEachFieldRange();
      const temp = {};
      for (let el in answeredValue) {
        if (el === field) {
          const split = el.split('_');
          temp['type'] = 'range';
          temp['field'] = el;
          temp['columnId'] = split[1];
          temp['fieldId'] = split[2];
          temp['isValid'] = this.verifyNumberisBetween(answeredValue[el], start, end);
          temp['errorText'] = errorMsg;
          return temp;
        }
      }

    }

  }

  precision(params, errorMsg) {
    if (params.length > 1) {
      const field_name = params[0].substring(params[0].indexOf("[") + 1, params[0].indexOf("]"))

      const temp = {
        type: 'Field_precision',
        question: field_name.split('_')[0],
        column: field_name.split('_')[1],
        row: field_name.split('_')[2],
        precision: params[1],
        errMsg: errorMsg,
        field: field_name
      }
      return temp;
    } else {
      let precision = this.findAndReplaceVarWithData(params[0]);
      let evalPrecision = eval(precision);
      this._session.api.local.save(Config.CONSTANTS.PRECISION, evalPrecision ? evalPrecision : precision);
    }
  }

  order(params) {
    let item = params[0], order_type = params[1], order_items = params[2], exception_list = params[3], anchor = params[4];
    console.log("order:: item: " + item + ", order_type: " + order_type + ", order_items: "
      + order_items + ", exception_list: " + exception_list + ", anchor: " + anchor);
    // item types - ROWS 
    // order types - RANDOMLY/ALPHABETICALLY/DEFAULT
    // anchor types - POSITION/TOP/BOTTOM
    if (order_type && (order_type === 'RANDOMLY' || order_type === 'ALPHABETICALLY')) {
      if (!order_items) {
        order_items = 'ALL';
      }
      let itemPositions = order_items.split(",");
      console.log('order:: itemPositions: ' + itemPositions);

      let exceptionPositions;
      if (exception_list) {
        exceptionPositions = exception_list.split(",");
        if (exceptionPositions.length > 0) {
          if (!anchor) {
            anchor = 'POSITION';
          }
        }
      }
      console.log('order:: exceptionPositions: ' + exceptionPositions + ', anchor: ' + anchor);

      // we have to order based on rows only even if rule is on a column to keep row data intact
      if (item === 'ROWS' || item === 'COLUMNS') {
        // item = item.toLowerCase();
        let itemPositionIndexes, exceptionIndexes;
        if (exceptionPositions && item === 'ROWS') {
          exceptionIndexes =
            this.currentQuestionOptions['rows'].map((row, i) => exceptionPositions.includes(row[0]['value']) || row[0]['value'] === 'N/A' ? i : undefined).filter(x => x);
        }

        if (itemPositions.includes('ALL') || item === 'COLUMNS') {
          itemPositionIndexes = undefined;
        } else {
          itemPositionIndexes =
            this.currentQuestionOptions['rows'].map((row, i) => itemPositions.includes(row[0]['value']) ? i : undefined);//.filter(x => x);
          itemPositionIndexes = itemPositionIndexes.filter(item => item !== undefined);
        }
        // if(order_type === 'RANDOMLY') {
        //   this.currentQuestionOptions['rows'] = 
        //     this.customShuffleArray(this.currentQuestionOptions['rows'], itemPositionIndexes, exceptionIndexes);
        // } else if(order_type === 'ALPHABETICALLY') {
        //   this.currentQuestionOptions['rows'] = 
        //     this.customSortArray(this.currentQuestionOptions['rows'], itemPositionIndexes, exceptionIndexes, 'value');
        // }
        console.log('before currentQuestionOptions rows -');
        console.log(this.currentQuestionOptions['rows']);
        this.currentQuestionOptions['rows'] =
          this.orderArray(this.currentQuestionOptions['rows'], itemPositionIndexes, exceptionIndexes, order_type, 'value', anchor);
        console.log('after currentQuestionOptions rows -');
        console.log(this.currentQuestionOptions['rows']);
      }
    } else {
      console.log('order:: skipping order for order_type: ' + order_type);
    }
  }

  orderArray(array, itemPositionIndexes, exceptionIndexes, orderType, sortKey, anchor) {
    console.log("orderArray:: array: " + array.length + ", itemPositionIndexes: " + itemPositionIndexes + ", exceptionIndexes: "
      + exceptionIndexes + ", orderType: " + orderType + ", sortKey: " + sortKey + ", anchor: " + anchor);
    // todo - apply anchor position for exceptionIndexes
    let arrayToOrder: any = [];
    let isItemPositionIndexAbsent = false;
    let isExPositionIndexAbsent = false;
    if (itemPositionIndexes) {
      for (let i = 0; i < array.length; i++) {
        if (itemPositionIndexes.includes(i)) {
          arrayToOrder.push(array[i]);
        }
      }
    } else {
      isItemPositionIndexAbsent = true;
      // let clone1 = (array) => array.map(item => Array.isArray(item) ? clonedeep(item) : {...item});
      arrayToOrder = lodashClonedeep(array);
    }
    console.log("orderArray:: arrayToOrder: " + arrayToOrder.length + ", isItemPositionIndexAbsent: " + isItemPositionIndexAbsent);

    let arrayToOrderExcludingEx: any = [];
    if (exceptionIndexes) {
      for (let i = 0; i < arrayToOrder.length; i++) {
        if (!exceptionIndexes.includes(i)) {
          arrayToOrderExcludingEx.push(array[i]);
        }
      }
    } else {
      isExPositionIndexAbsent = true;
      arrayToOrderExcludingEx = lodashClonedeep(arrayToOrder);
    }

    console.log("orderArray:: arrayToOrderExcludingEx: " + arrayToOrderExcludingEx.length
      + ", isExPositionIndexAbsent: " + isExPositionIndexAbsent);

    if (orderType === 'RANDOMLY') {
      arrayToOrderExcludingEx = this.shuffleArray(arrayToOrderExcludingEx);
    } else if (orderType === 'ALPHABETICALLY') {
      arrayToOrderExcludingEx = this.sortArray(arrayToOrderExcludingEx, sortKey);
    }
    console.log("orderArray:: after operation - arrayToOrderExcludingEx: " + arrayToOrderExcludingEx.length);

    for (let i = 0; i < array.length; i++) {
      if ((isItemPositionIndexAbsent || itemPositionIndexes.includes(i))
        && (isExPositionIndexAbsent || !exceptionIndexes.includes(i))) {
        array[i] = arrayToOrderExcludingEx[0];
        arrayToOrderExcludingEx.shift();
      }
    }

    let arrayException: any = [];
    if (exceptionIndexes && (anchor === 'TOP' || anchor === 'BOTTOM')) {
      exceptionIndexes.forEach((exIdx, idx) => {
        let removedEx = array.splice(exIdx - idx, 1);
        arrayException.push(...removedEx);
      });
      console.log("orderArray:: array after removing exceptions: " + array.length + ", arrayException: " + arrayException.length);
      if (arrayException.length > 0) {
        if (anchor === 'TOP') {
          array.unshift(arrayException);
        } else if (anchor === 'BOTTOM') {
          array.push(...arrayException);
        }
      }
    }
    return array;
  }

  /*customSortArray(array, itemPositionIndexes, exceptionIndexes, sortKey) {
    let listToSort = [];
    if(itemPositionIndexes) {
      for(var i=0; i < array.length; i++){
        if(itemPositionIndexes.includes(i)){
          listToSort.push(array[i]);
        }
      }
    } else {
      listToSort = array;
    }

    let listToSortExcludingEx = [];
    if(exceptionIndexes) {
      for(var i=0; i < listToSort.length; i++){
        if(!exceptionIndexes.includes(i)){
          listToSortExcludingEx.push(array[i]);
        }
      }
    } else {
      listToSortExcludingEx = listToSort;
    }

    listToSortExcludingEx = this.sortArray(listToSortExcludingEx, sortKey);
    for(var i=0; i < array.length; i++){
      if(itemPositionIndexes.includes(i) && !exceptionIndexes.includes(i)){
        array[i] = listToSortExcludingEx[0];
        listToSortExcludingEx.shift();
      }
    }
    return array;
  }*/

  sortArray(array, sortKey) {
    console.log("sortArray:: before array: " + array.length + ", sortKey: " + sortKey);
    array = array.sort(function (a, b) {
      let x = a[1][sortKey];
      let y = b[1][sortKey];
      return ((x < y) ? -1 : ((x > y) ? 1 : 0));
    });
    console.log("sortArray:: after array: " + array.length);
    return array;
  }

  /*customShuffleArray(array, itemPositionIndexes, exceptionIndexes) {
    let listToShuffle = [];
    if(itemPositionIndexes) {
      for(var i=0; i < array.length; i++){
        if(itemPositionIndexes.includes(i)){
          listToShuffle.push(array[i]);
        }
      }
    } else {
      listToShuffle = array;
    }

    let listToShuffleExcludingEx = [];
    if(exceptionIndexes) {
      for(var i=0; i < listToShuffle.length; i++){
        if(!exceptionIndexes.includes(i)){
          listToShuffleExcludingEx.push(array[i]);
        }
      }
    } else {
      listToShuffleExcludingEx = listToShuffle;
    }

    listToShuffleExcludingEx = this.shuffleArray(listToShuffleExcludingEx);
    for(var i=0; i < array.length; i++){
      if(itemPositionIndexes.includes(i) && !exceptionIndexes.includes(i)){
        array[i] = listToShuffleExcludingEx[0];
        listToShuffleExcludingEx.shift();
      }
    }
    return array;
  }*/

  shuffleArray(array) {
    console.log("shuffleArray:: before array: " + array.length);
    for (let i = array.length - 1; i > 0; i--) {
      let j = Math.floor(Math.random() * (i + 1));
      let holder = array[i];
      array[i] = array[j];
      array[j] = holder;
    }
    console.log("shuffleArray:: before after: " + array.length);
    return array;
  }

  ask(params) {
    let item = params[0], expression = params[1];
    item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
    console.log("ask:: item: " + item + ", expression: " + expression);
    let ask = this.parseExpression(expression);
    if (ask) {
      this.askRulesMap.set(item, ask);
      return 'askQuestion';
    } else {
      ask = false;
      this.askRulesMap.set(item, ask);
      return 'skipQuestion';
    }
  }

  skip(params) {
    let item = params[0], expression = params[1];
    item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
    console.log("skip:: item: " + item + ", expression: " + expression);
    let skip = this.parseExpression(expression);
    if (skip) {
      this.skipRulesMap.set(item, skip);

    } else {
      skip = false;
      this.skipRulesMap.set(item, skip);
    }
    console.log('skip:: status: ' + skip);
  }

  skipto(params) {
    let item = params[0], expression = params[1];
    item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
    console.log("skipto:: item: " + item + ", expression: " + expression);
    let skipto = this.parseExpression(expression);
    if (skipto) {
      // this.skipToRulesMap.set(this.currentQuestion, item);
      this.skipToRulePresent = true;
      this.skipToQuestion = item;
    }
    console.log('skipto:: status: ' + skipto);
  }

  //show(ROWS##1,2,3##COLUMNS##A##[2Q10_A_7] = 1 AND [C0_A] = 1)
  // ALL can not be an option here !!
  show(params) {
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], expression = params[4];
    console.log("show - item: " + item + ", item_val: " + item_val + ", in_item: " + in_item
      + ", in_item_val: " + in_item_val + ", expression: " + expression);
    let show = this.parseExpression(expression);
    console.log('show:: show status: ' + show);
    if (!show) {
      let items;
      if (item_val) {
        items = item_val.split(",");
        console.log('show:: items: ' + items);
      }
      if (item === "ROWS" && items) {
        let rowsToShow = this.currentQuestionOptions['rows'].filter(row => !items.includes(row[0]['value']));
        console.log('rowsToShow - ');
        console.log(rowsToShow);
        if (rowsToShow && rowsToShow.length > 0) {
          this.currentQuestionOptions['rows'] = rowsToShow;
        }
      } else if (item === "COLUMNS" && items) {
        //get index of items -
        let selectedColIdxes = this.currentQuestionOptions['columns'].map((col, i) => items.includes(col['columnId']) ? i : undefined).filter(x => x);
        console.log('selectedColIdxes - ' + selectedColIdxes);

        let colsToShow = this.currentQuestionOptions['columns'].filter(col => !items.includes(col['columnId']));
        console.log('colsToShow - ');
        console.log(colsToShow);
        if (colsToShow && colsToShow.length > 0) {
          this.currentQuestionOptions['columns'] = colsToShow;
        }

        // filter rows for the corresponding columns (items)
        let rowsToShow = this.currentQuestionOptions['rows']
          .map(r => r.filter(e => !selectedColIdxes.includes(r.indexOf(e))));

        console.log('rowsToShow - ');
        console.log(rowsToShow);
        if (rowsToShow && rowsToShow.length > 0) {
          this.currentQuestionOptions['rows'] = rowsToShow;
        }
      }
    }
  }

  //dont_show(ROWS##1,2,3##COLUMNS##A##[2Q10_A_7] = 1 AND [C0_A] = 1)
  dont_show(params) {
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], expression = params[4];
    console.log("dont_show - item: " + item + ", item_val: " + item_val + ", in_item: " + in_item
      + ", in_item_val: " + in_item_val + ", expression: " + expression);
    let dontShow = this.parseExpression(expression);
    console.log('dont_show:: dontShow status: ' + dontShow);
    if (dontShow) {
      let items;
      if (item_val) {
        items = item_val.split(",");
        console.log('dont_show:: items: ' + items);
      }

      if (item === "ROWS" && items) {
        let rowsToShow = this.currentQuestionOptions['rows'].filter(row => !items.includes(row[0]['value']));
        console.log('rowsToShow-');
        console.log(rowsToShow);
        if (rowsToShow && rowsToShow.length > 0) {
          //remove values from hidden row values - 
          /*
          this.currentQuestionOptions['rows'].filter(row => items.includes(row[0]['value'])).forEach((row) => {
            row.forEach((el) => {
              let cellval = el['value'];
              if (el['valueType'] === 'SYMBOL') {
                if(cellval === 'Number') {
                  el['number'] = 0;
                } else if(cellval === 'Percent') {
                  el['percent'] = 0;
                }
              }
            });
          });
          */
          this.currentQuestionOptions['rows'] = rowsToShow;
        }
      } else if (item === "COLUMNS" && items) {
        //get index of items -
        let selectedColIdxes = this.currentQuestionOptions['columns'].map((col, i) => items.includes(col['columnId']) ? i : undefined).filter(x => x);
        console.log('selectedColIdxes - ' + selectedColIdxes);

        let colsToShow = this.currentQuestionOptions['columns'].filter(col => !items.includes(col['columnId']));
        console.log('colsToShow -');
        console.log(colsToShow);
        if (colsToShow && colsToShow.length > 0) {
          this.currentQuestionOptions['columns'] = colsToShow;
        }

        // filter rows for the corresponding columns (items)
        let rowsToShow = this.currentQuestionOptions['rows']
          .map(r => r.filter(e => !selectedColIdxes.includes(r.indexOf(e))));

        console.log('rowsToShow - ');
        console.log(rowsToShow);
        if (rowsToShow && rowsToShow.length > 0) {
          this.currentQuestionOptions['rows'] = rowsToShow;
        }
      }
    }
  }

  disable(params) {
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], expression = params[4];
    let disableStatus = this.parseExpression(expression);
    let colmId: any;
    if (disableStatus) {
      let items;
      if (item_val) {
        items = item_val.split(",");
        this.currentQuestionOptions['rows'].forEach((row, index) => {
          let firstRowVal;
          if (item == 'ROWS' || item == 'FIELD') {
            // if (item == 'ROWS') {
            firstRowVal = row[0]['value'];
          } else {
            firstRowVal = this.convertToString(row[0]['value']);
          }
          if (firstRowVal) {

            /** Disable FIELD */
            let cell;
            if (item == 'FIELD') {
              items.forEach(element => {
                cell = element.split("_");
                this.currentQuestionOptions['columns'].forEach((col, ind) => {
                  if (cell[1] == col.columnId) {
                    colmId = ind;
                  }
                });
                if (cell[2] == firstRowVal) {
                  if (row[colmId]['valueType'] !== 'Label') {
                    row[colmId]['disable'] = true;
                  }
                }
              });
            } else if (item == 'ROWS') {
              /** Disable ROWS */
              if (items.includes(firstRowVal)) {
                row.forEach((el, elInd) => {
                  if (el['valueType'] !== 'Label') {
                    el['disable'] = true;
                  }
                });
              }
              if (this.currentSelectedData[0].option_type == 'Rating') {
                this.currentSelectedData.forEach((el) => {
                  if (item_val.includes(el.selected_row)) {
                    el['disable'] = true;
                  }
                });
              }
            } else {
              /**COLUMNS */
              row.forEach((el, elInd) => {
                let col = this.convertToString(elInd - 1);
                if (items.includes(col)) {
                  if (el['valueType'] !== 'Label') {
                    el['disable'] = true;
                  }
                }
              });
            }
          }
        });
      }
    }
  }

  show_data(params) {
    let type = params[0], expdata = params[1];
    let assignmentVal = null;
    console.log("show_data - type: " + type + ", expdata: " + expdata);
    let exp_json = JSON.parse(expdata);
    // let exp_json = JSON.parse(expdata.replaceAll("\'", ""));
    //console.log('show_data - exp_json: ' + exp_json);
    if (exp_json) {
      let expStatus = false;
      for (let z = 0; z < exp_json.length; z++) {
        console.log('show_data - exp_json[' + z + '].expression: ' + exp_json[z].expression
          + ', exp_json[' + z + '].data: ' + exp_json[z].data);
        if (exp_json[z].expression) {
          if (this.defaultStr === exp_json[z].expression) {
            assignmentVal = exp_json[z].data;
            break;
          } else {
            expStatus = this.parseExpression(exp_json[z].expression);
            console.log('show_data - expStatus: ' + expStatus);
            if (expStatus) {
              assignmentVal = exp_json[z].data;
              break;
            }
          }
        }
      }
      console.log('show_data - assignmentVal: ' + assignmentVal);
      // type is NOT REQUIRED as of now, reserved for future - possible values - TEXT/ERROR
    }
    return assignmentVal;
  }

  unique(params) {
    let returnData: any = {};
    returnData.status = true;
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], expression = params[4];
    console.log("unique - item: " + item + ", item_val: " + item_val + ", in_item: "
      + in_item + ", in_item_val: " + in_item_val + ", expression: " + expression);
    // expression - optional
    if (expression) {
      let evalStatus = this.parseExpression(expression);
      console.log('unique:: evalStatus : ' + evalStatus);
      // if expression evaluation is not true then return .. no need to check for uniqueness
      if (evalStatus) {
        // if expression is not present OR uniqueStatus is true then apply rule
        // item types - ROWS
        // in item types - COLUMNS
        let items, inItems;
        if (!in_item_val) {
          in_item_val = "A"; // TODO - change it to first column from Options of a question
        }
        inItems = in_item_val.split(",");
        console.log('unique:: inItems: ' + inItems);

        if (item_val) {
          items = item_val.split(",");
          console.log('unique:: items: ' + items);

          //  check for uniqueness of row item values for the given column
          console.log('unique:: currentSelectedData - ');
          console.log(this.currentSelectedData);
          for (let col of inItems) {
            let filterRows = this.currentSelectedData.filter(item => item['selected_header'] === col).filter(data => items.includes(data['selected_row'].toString()));
            console.log('filterRows - ');
            console.log(filterRows);
            if (filterRows) {
              let filteredValues = filterRows.map((rd) => {
                return rd['selected_value'];
              });
              console.log('filteredValues - ');
              console.log(filteredValues);
              if (filteredValues) {
                if (this.hasDuplicates(filteredValues)) {
                  let filteredRowIdxes = filterRows.map((rdata) => {
                    return rdata['selected_row'];
                  });
                  let colIdx = -1;
                  this.currentQuestionOptions['columns'].find(function (c, i) {
                    if (c['columnId'] === col) {
                      colIdx = i;
                      return i;
                    }
                  });
                  if (colIdx > -1 && filteredRowIdxes) {
                    console.log('filteredRowIdxes-');
                    console.log(filteredRowIdxes);
                    console.log('colIdx-' + colIdx);
                    this.currentQuestionOptions['rows'].forEach((r) => {
                      let roVal = r[0]['value'];
                      if (roVal && filteredRowIdxes.indexOf(roVal) >= 0) {
                        console.log('roVal - ' + roVal);
                        r[colIdx]['invalid'] = true;
                      }
                    });
                  }
                  returnData.status = false;
                  // returnData.message = "Values should be unique for Rows " + item_val + " in Column " + col;
                  // returnData.message = this.findAndReplaceQuestionIdWithNumber(this.errorDetail);
                  returnData.message = this.errorDetail;
                  // 
                  break;
                }
              }
            }
          }
        }
      }
    }

    return returnData;
  }

  hasDuplicates(arr) {
    return new Set(arr).size !== arr.length;
  }

  sum(params) {
    let returnData: any = {};
    returnData.status = true;
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], part_expression = params[4];
    console.log("sum - item: " + item + ", item_val: " + item_val + ", in_item: "
      + in_item + ", in_item_val: " + in_item_val + ", part_expression: " + part_expression);

    let operator = part_expression.match(this.operatorPattern);
    if (operator) {
      operator = operator[0];
      console.log('sum:: operator : ' + operator);
      let exp = part_expression.substring(part_expression.indexOf(operator) + operator.length);
      console.log('sum:: exp : ' + exp);
      if (exp) {
        let expData = this.getExpressionData(exp);
        expData = Number(expData);
        console.log('sum:: expData : ' + expData);
        if (expData) {
          // item types - ROWS
          // in item types - COLUMNS
          let items, inItems;
          if (!in_item_val) {
            in_item_val = "A"; // TODO - change it to first column from Options of a question
          }
          inItems = in_item_val.split(",");

          console.log('sum:: inItems: ' + inItems);
          if (item_val) {
            items = item_val.split(",");
            console.log('sum:: items: ' + items);
            // check for sum of row item values for the given column and compare with expData
            console.log('sum:: currentSelectedData - ');
            console.log(this.currentSelectedData);
            for (let col of inItems) {
              let filterRows;
              if (items[0] === 'ALL') {
                console.log('items - all');
                filterRows = this.currentSelectedData.filter(item => item['selected_header'] === col);
              } else {
                filterRows = this.currentSelectedData.filter(item => item['selected_header'] === col)
                  .filter(data => items.includes(data['selected_row'].toString()));
              }
              console.log('filterRows - ');
              console.log(filterRows);
              if (filterRows) {
                let filteredValues = filterRows.map((rd) => {
                  return rd['selected_value'];
                });
                console.log('filteredValues - ');
                console.log(filteredValues);
                if (filteredValues && filteredValues.length > 0) {
                  let sumVal = this.sumArray(filteredValues);
                  if (!this.compareValues(sumVal, operator, expData)) {
                    let filteredRowIdxes = filterRows.map((rdata) => {
                      return rdata['selected_row'];
                    });
                    let colIdx = -1;
                    this.currentQuestionOptions['columns'].find(function (c, i) {
                      if (c['columnId'] === col) {
                        colIdx = i;
                        return i;
                      }
                    });
                    if (colIdx > -1 && filteredRowIdxes) {
                      console.log('filteredRowIdxes-');
                      console.log(filteredRowIdxes);
                      console.log('colIdx-' + colIdx);
                      this.currentQuestionOptions['rows'].forEach((r) => {
                        let roVal = r[0]['value'];
                        if (roVal && filteredRowIdxes.indexOf(roVal) >= 0) {
                          console.log('roVal - ' + roVal);
                          r[colIdx]['invalid'] = true;
                        }
                      });
                    }
                    returnData.status = false;
                    // returnData.message = "Sum of Rows '" + item_val + "' in Column '" + col
                    //   + "' should be " + this.getOperatorText(operator) + " " + expData
                    //   + ", current sum is " + sumVal;
                    // returnData.message = this.findAndReplaceQuestionIdWithNumber(this.errorDetail) + ". Current Sum = " + sumVal;
                    returnData.message = this.errorDetail + operator + " " + expData + ". {CurrentSum} = " + sumVal;
                    break;
                  }
                }
              }
            }
          }
        }
      }
    }
    return returnData;
  }

  getOperatorText(operator) {
    let opText;
    switch (operator) {
      case '=':
        opText = "equal to";
        break;
      case '<>':
      case '!=':
        opText = "not equal to";
        break;
      case '>':
        opText = "greater than";
        break;
      case '<':
        opText = "less than";
        break;
      case '>=':
        opText = "greater than or equal to ";
        break;
      case '<=':
        opText = "less than or equal to ";
        break;
      default:
        opText = "NA";
    }
    return opText;
  }

  compareValues(leftVal, operator, rightVal) {
    let compVal;
    switch (operator) {
      case '=':
        compVal = leftVal === rightVal;
        break;
      case '<>':
      case '!=':
        compVal = leftVal !== rightVal;
        break;
      case '>':
        compVal = leftVal > rightVal;
        break;
      case '<':
        compVal = leftVal < rightVal;
        break;
      case '>=':
        compVal = leftVal >= rightVal;
        break;
      case '<=':
        compVal = leftVal <= rightVal;
        break;
      default:
        compVal = true;
    }
    return compVal;
  }

  sumArray(arr) {
    return arr.reduce((a, b) => (Number(a) || 0) + (Number(b) || 0), 0);
  }

  check(params, errorDetail) {
    let expression = params[0];
    console.log("check:: expression: " + expression);
    let returnData: any = {};
    returnData.status = true;

    if (expression) {
      // this.formErrorMessage = true;
      // this.errorMessage = '';
      this.onScreenRuleExecution = true;
      let evalStatus = this.parseExpression(expression);
      console.log('check:: evalStatus: ' + evalStatus);
      if (!evalStatus) {
        returnData.status = false;
        // returnData.message = "Data validation failed for condition - " + expression;
        // returnData.message = this.findAndReplaceQuestionIdWithNumber(this.errorDetail);
        returnData.message = errorDetail;
      }
    }
    // this.formErrorMessage = false;
    // this.errorMessage = '';
    return returnData;
  }

  // createErrorMessage(expression) {
  //   expression = expression.trim();
  //   console.log("createErrorMessage:: " + expression);
  //   // check if the expression is valid
  //   let result = expression.match(this.pattern);
  //   if (result) {
  //     // find operator -
  //     let opval = expression.match(this.operatorPattern);
  //     console.log("createErrorMessage:: opval: " + opval);
  //     if (opval) {
  //       opval = opval[0];
  //       let expressionArray = expression.split(opval);
  //       console.log("createErrorMessage:: expressionArray: " + expressionArray);
  //       if (expressionArray && expressionArray.length == 2) {
  //         let expData = expressionArray[0].trim();
  //         let expValue = expressionArray[1].trim();
  //         console.log("createErrorMessage:: expData: " + expData + ", expValue: " + expValue);
  //         let funVal = expData.match(this.funPattern);
  //         if (funVal) {
  //           funVal = funVal[0];
  //           console.log("createErrorMessage:: funVal: " + funVal);
  //           // function - COUNT|MIN|MAX|SUM|DURATION
  //           this.errorMessage = this.errorMessage + funVal + " of ";
  //         } else {
  //           let items = expData.match(this.itemsPattern);
  //           if (items) {
  //             items.forEach((item) => {
  //               let itemVar = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
  //               let params = itemVar.split("_");
  //               let qType, col, row, errorData;
  //               if(params && params.length > 1) {
  //                 // qType = this.getQuestionType(params[0]);
  //                  //get column / row description and form error message
  //                 col = params[1];
  //                 if(params.length > 2) {
  //                   row = params[2];
  //                 }
  //               }
  //               expData = expData.replace(item, errorData);
  //             });
  //             console.log("createErrorMessage - expData: " + expData);
  //             // this.errorMessage = this.errorMessage;
  //           }
  //         }
  //       }
  //     }
  //   }
  //   else {
  //     console.log("createErrorMessage:: Unable to create Error Message!");
  //   }
  // }

  check_item(params) {
    let item = params[0], item_val = params[1], in_item = params[2], in_item_val = params[3], expression = params[4];
    console.log("check_item - item: " + item + ", item_val: " + item_val + ", in_item: "
      + in_item + ", in_item_val: " + in_item_val + ", expression: " + expression);
    let returnData: any = {};
    returnData.status = true;

    // item types - ROWS
    // in item types - COLUMNS
    let items, inItems;
    if (!in_item_val) {
      in_item_val = "A"; // TODO - change it to first column from Options of a question
    }
    inItems = in_item_val.split(",");
    console.log('check_item:: inItems: ' + inItems);

    if (item_val) {
      items = item_val.split(",");
      console.log('check_item:: items: ' + items);
      let evalStatus = this.parseExpression(expression);
      console.log('check_item:: evalStatus : ' + evalStatus);
      if (evalStatus) {
        console.log('check_item:: currentSelectedData - ');
        console.log(this.currentSelectedData);
        for (let col of inItems) {
          let filterRows = this.currentSelectedData.filter(item => item['selected_header'] === col).filter(data => items.includes(data['selected_row'].toString()));
          console.log('filterRows - ');
          console.log(filterRows);
          if (filterRows) {
            let filteredValues = filterRows.map((rd) => {
              return rd['selected_value'];
            });
            console.log('filteredValues - ');
            console.log(filteredValues);
            if (filteredValues) {
              if (false) { // TODO - find what to evaluate for the given rows in column??? need to redefine rule!!
                // returnData.status = false;
                // returnData.message = "";
                // break;
              }
            }
          }
        }
      }
    }
    return returnData;
  }

  autosum(params) {
    // Handled in rule Engine .. no need to execute 
    return true;
    // let item = params[0], positionData = params[1];
    // console.log("autosum - item: " + item + ", positionData: " + positionData);
    // // item - ROWS/COLUMNS
    // if (positionData) {
    //   let positions = positionData.split(",");
    //   if (positions && positions.length > 0) {
    //     // TODO - check if we can add it to question during load itself
    //     if (item == 'ROWS') {
    //       item = item.toLowerCase();
    //       this.currentQuestionOptions[item].forEach((row) => {
    //         for (let val of row) {
    //           if(positions.includes(val['value'])) {
    //             val['autosum'] = true;
    //             break;
    //           }
    //         }
    //       });
    //     } else if (item == 'COLUMNS') {
    //       item = item.toLowerCase();
    //       for (let column of this.currentQuestionOptions[item]) {
    //         if(positions.includes(column['columnId'])) {
    //           column['autosum'] = true;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  // set_default(Q23_B##0)
  // set_default(Q21_C_1##NA)
  set_default(params) {
    let question = params[0], expression = params[1], value;
    console.log("set_default:: question: " + question + ", expression: " + expression);
    let parsedAssignmentVal = this.checkAndReplaceItems(expression);
    console.log('set_default:: parsedAssignmentVal 1: ' + parsedAssignmentVal);

    if (parsedAssignmentVal) {
      value = parsedAssignmentVal;
    } else {
      parsedAssignmentVal = this.replaceVariableValue(expression);
      console.log('set_default:: parsedAssignmentVal 2: ' + parsedAssignmentVal);
      if (parsedAssignmentVal) {
        value = parsedAssignmentVal;
      } else {
        value = expression;
      }
    }

    console.log('set_default:: final value: ' + value);

    if (value) {
      let qType, col, row, setAll = false;
      // let itemVar = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
      if (question) {
        let questionParams = question.split("_");
        console.log('set_default:: questionParams: ' + questionParams);
        question = questionParams[0];
        if (questionParams && questionParams.length > 1) {
          col = questionParams[1];
          if (questionParams.length > 2) {
            row = questionParams[2];
          }
          qType = this.getQuestionType(question);
        }
      } else {
        qType = this.questionType;
        setAll = true;
      }

      console.log("set_default - question: " + question + ", col: " + col + ", row: " + row + ", qType: " + qType + ", setAll: " + setAll);
      let colIdx = -1;
      if (col) {
        this.currentQuestionOptions['columns'].forEach((c, cidx) => {
          if (c['columnId'] === col) {
            colIdx = cidx;
          }
        });
      }

      if (qType && (qType === 'Number' || qType === 'Percent' || qType === 'Text' || qType === 'Large Text'
        || qType === 'Number And Single choice' || qType === 'Text And Single choice'
        || qType === 'Percent And Single choice' || qType === 'Select all that apply And Text'
        || qType === 'Select all that apply And Number' || qType === 'SLIDER_CATEGORY')) {
        this.currentQuestionOptions['rows'].forEach((rs) => {
          if (setAll) {
            rs.forEach((r) => {
              if (r['valueType'] === 'SYMBOL') {
                let cellval = r['value'];
                let cellvalueType = r['valueType'];
                if (cellval === 'Number') {
                  r['number'] = value;
                } else if (cellval === 'Percent') {
                  r['percent'] = value;
                } else if (cellval === 'Text' || cellval === 'Large Text') {
                  r['enteredText'] = value;
                } else if (cellvalueType === 'SLIDER_CATEGORY') {
                  r['sliderValue'] = Number(value);
                }
              }
            });
          } else if (colIdx > -1 && row && rs[0]['value'] == row) {
            let cell = rs[colIdx];
            if (cell) {
              let val = cell['value'];
              let valueType = cell['valueType'];
              if (val === 'Number') {
                cell['number'] = value;
              } else if (val === 'Percent') {
                cell['percent'] = value;
              } else if (val === 'Text' || val === 'Large Text') {
                cell['enteredText'] = value;
              } else if (valueType === 'SLIDER_CATEGORY') {
                cell['sliderValue'] = Number(value);
              }
            }
          } else if (colIdx > -1 && row == undefined && qType === 'SLIDER_CATEGORY') { /** If requesting for the column, eg: questionParams = [S1_A]. Now handling only for slider*/
            let cell = rs[colIdx];
            if (cell) {
              let valueType = cell['valueType'];
              if (valueType === 'SLIDER_CATEGORY') {
                cell['sliderValue'] = Number(value);
              }
            }
          }
        });
      }
    }
  }

  running_total(params) {
    // Redundant as the same is handled using autosum rule - Handled in rule Engine .. no need to execute 
    return true;
    // let item = params[0], positionData = params[1];
    // console.log("running_total - item: " + item + ", positionData: " + positionData);
    // // item - ROWS/COLUMNS
    // if (positionData) {
    //   let positions = positionData.split(",");
    //   if (positions && positions.length > 0) {
    //     // TODO - check if we can add it to question during load itself
    //     if (item == 'ROWS') {
    //       item = item.toLowerCase();
    //       this.currentQuestionOptions[item].forEach((row) => {
    //         for (let val of row) {
    //           if(positions.includes(val['value'])) {
    //             val['running_total'] = true;
    //             break;
    //           }
    //         }
    //       });
    //     } else if (item == 'COLUMNS') {
    //       item = item.toLowerCase();
    //       for (let column of this.currentQuestionOptions[item]) {
    //         if(positions.includes(column['columnId'])) {
    //           column['running_total'] = true;
    //         }
    //       }
    //     }
    //   }
    // }
  }

  compute(params) {
    let varName = params[0], expdata = params[1];
    console.log("compute - varName: " + varName + ", expdata: " + expdata);
    if (varName) {
      let exp_json = JSON.parse(expdata.replaceAll("\'", ""));
      //console.log('compute - exp_json: ' + exp_json);
      let assignmentVal = null;
      if (exp_json) {
        let expStatus = false;
        for (let z = 0; z < exp_json.length; z++) {
          console.log('compute - exp_json[' + z + '].expression: ' + exp_json[z].expression
            + ', exp_json[' + z + '].data: ' + exp_json[z].data);
          if (exp_json[z].expression) {
            if (this.defaultStr === exp_json[z].expression) {
              assignmentVal = exp_json[z].data;
              break;
            } else {
              expStatus = this.parseExpression(exp_json[z].expression);
              console.log('compute - expStatus: ' + expStatus);
              if (expStatus) {
                assignmentVal = exp_json[z].data;
                break;
              }
            }
          }
        }
      } else if (expdata) {
        assignmentVal = expdata;
      }

      let parsedAssignmentVal = this.replaceVariableValue(assignmentVal);

      console.log('compute - parsedAssignmentVal: ' + parsedAssignmentVal);
      // TODO evaluate return value as well if it is an expression like "[S6] * [S7D_4]" and "DURATION([Q11_A_1],[2018, 3, 14])"
      parsedAssignmentVal = this.checkAndReplaceItems(parsedAssignmentVal);
      console.log('compute - assignmentVal after: ' + parsedAssignmentVal);
      if (parsedAssignmentVal) {
        this.setItemValue(varName, parsedAssignmentVal);
        this.updateComputeRule(varName, parsedAssignmentVal);
      }
    }
  }

  replaceVariableValue(varText) {
    let itemVal, pipeVar;
    let items = varText.match(this.itemsPattern);
    if (items) {
      items.forEach((item) => {
        itemVal = this.getItemValue(item.substring(item.indexOf("[") + 1, item.indexOf("]")));
        varText = varText.replace(item, itemVal);
      });
      console.log("replaceVariableValue - varText: " + varText);
      return varText;
    }
    return varText;
  }

  pipe(params) {
    let expression = params[0];
    console.log("pipe:: expression: " + expression);
    //TODO evaluate for function and then values like terminate .. 
    // let expData = this.getExpressionData(expression);

    let parsedAssignmentVal = this.checkAndReplaceItems(expression);
    console.log('pipe:: parsedAssignmentVal 1: ' + parsedAssignmentVal);

    if (parsedAssignmentVal || (typeof (parsedAssignmentVal) === 'number' && parsedAssignmentVal >= 0)) {
      return parsedAssignmentVal;
    }
    parsedAssignmentVal = this.replaceVariableValue(expression);
    console.log('pipe:: parsedAssignmentVal 2: ' + parsedAssignmentVal);
    return parsedAssignmentVal;
  }

  pipe_conditional(params) {
    let type = params[0], expdata = params[1];
    console.log("pipe_conditional - type: " + type + ", expdata: " + expdata);
    let exp_json = JSON.parse(expdata.replaceAll("\'", ""));
    //console.log('compute - exp_json: ' + exp_json);
    if (exp_json) {
      let expStatus = false;
      let assignmentVal = null;
      for (let z = 0; z < exp_json.length; z++) {
        console.log('compute - exp_json[' + z + '].expression: ' + exp_json[z].expression
          + ', exp_json[' + z + '].data: ' + exp_json[z].data);
        if (exp_json[z].expression) {
          if (this.defaultStr === exp_json[z].expression) {
            assignmentVal = exp_json[z].data;
            break;
          } else {
            expStatus = this.parseExpression(exp_json[z].expression);
            console.log('compute - expStatus: ' + expStatus);
            if (expStatus) {
              assignmentVal = exp_json[z].data;
              break;
            }
          }
        }
      }
      console.log('pipe_conditional - assignmentVal: ' + assignmentVal);
      if (assignmentVal) {
        let parsedAssignmentVal = this.checkAndReplaceItems(assignmentVal);
        console.log('pipe:: parsedAssignmentVal 1: ' + parsedAssignmentVal);
        if (parsedAssignmentVal) {
          return parsedAssignmentVal;
        }
        parsedAssignmentVal = this.replaceVariableValue(assignmentVal);
        console.log('pipe:: parsedAssignmentVal 2: ' + parsedAssignmentVal);
        return parsedAssignmentVal;
      }
      return assignmentVal;
    }
  }

  link(params) {
    let type = params[0], hyperlink_text = params[1], url = params[2], hoverText = params[3];
    hoverText = hoverText ? hoverText : "";
    console.log("link - type: " + type + ", hyperlink_text: " + hyperlink_text + ", url: " + url);
    let link_data;
    if (type === "TEXT") {
      link_data = '<a target="_blank" href="' + url + '" title="' + hoverText + '">' + hyperlink_text + '</a>';
    } else if (type === "INLINE") {
      link_data = '<img src="' + url.toLowerCase() + '" alt="Info-Image" width="400" height="300">';
      // link_data = '<object data="' + url + '"></object>';
    } else {
      console.log('link: not a valid link type: ' + type);
    }
    console.log('link: link_data: ' + link_data);
    // Indicate admin about the links in doc after the upload of survey. 
    // Admin to provide multimedia for links before creating survey (prerequisite to create a survey) 
    return link_data;
  }

  // rule is read and applied in question itself - no need of this method
  rank_type(params) {
    /*
    if(this.questionType === 'Ranking') {
      let type = params[0];
      console.log("rank_type - type: " + type);
      if(type === "DRAG AND DROP" || type === "CLICK") {
        console.log('rank_type: Valid type: ' + type);
      } else {
        console.log('rank_type: not a valid type: ' + type);
        return;
      }
      //TODO - apply rank type display on Rank Question (DRAG OR DROP/CLICK)
      this.questionType === 'Ranking-' + type;
    }
    */
  }

  force_response(params) {
    let min_resp_count = params[0];
    let returnData: any = {};
    returnData.status = true;
    console.log("force_response:: min_resp_count: " + min_resp_count);
    if (min_resp_count) {
      if (['Large Text', 'Text'].includes(this.questionType)) {
        // this._session.api.local.save(Config.CONSTANTS.MINIMUM_RESPONSE, min_resp_count);
        let filterRows = this.currentSelectedData
          .filter(item => item['selected_value'] !== null && item['selected_value'] !== undefined && item['selected_value'] !== "");
        if (filterRows && filterRows.length >= min_resp_count) {
          console.log('force_response:: filtered currentSelectedData - ' + filterRows.length);
        } else {
          returnData.status = false;
          returnData.message = this.findAndReplaceQuestionIdWithNumber(this.errorDetail);
        }
      }
    } else {
      console.log("force_response:: invalid minimum response count");
    }
    return returnData;
  }

  hold_screen(params) {
    let duration = params[0], durType = params[1];
    console.log("hold_screen:: duration: " + duration + ", durType: " + durType);
    if (durType && durType === this.holdTypeMinute) {
      // convert minute to seconds -
      if (duration) {
        duration = duration * 60;
      }
    }
    // if no value the set to default - 30 Second
    if (!duration) {
      duration = 30;
    }
    if (this.questionType === 'Blank Table') {
      this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION, duration);
    }
  }

  // Helper functions -

  countDecimals(value) {
    console.log('countDecimals:: value: ' + value);
    if (Math.floor(value) === value) return 0;
    return value.toString().split(".")[1].length || 0;
  }

  parseExpression(expression) {
    let andConditions = [];
    let orConditions = [];
    let condition = '';
    let orTrue = false;

    // check for nested function - sum
    // if (expression.toLowerCase().startsWith("sum(")) {
    //   console.log('parseExpression:: sum fucntion: ' + expression);
    //   orTrue = this.parseAndCallNestedFunction(expression, this.secondaryDelimiter);
    //   return orTrue;
    // }

    if (expression.indexOf(" AND ") > 0) {
      andConditions = expression.split(" AND ");
      console.log('if - andConditions: ' + andConditions);
      // evaluate each AND condition
      for (let i = 0; i < andConditions.length; i++) {
        console.log('andConditions[' + i + ']: ' + andConditions[i]);
        orTrue = false;
        if (andConditions[i].indexOf(" OR ") > 0) {
          orConditions = andConditions[i].split(" OR ");
          console.log('orConditions: ' + orConditions);
          // evaluate each OR condition
          for (let j = 0; j < orConditions.length; j++) {
            // form error message
            // if(this.formErrorMessage) {
            //   this.errorMessage = this.errorMessage + this.createErrorMessage(orConditions[j]);
            // }

            // exit if any true since it is OR
            if (this.evaluateExpression(orConditions[j])) {
              orTrue = true;
              break;
            }
            // this.errorMessage =  this.errorMessage + ' OR ';
          }
          //orConditions.push.apply(orConditions, orArray);
        } else {
          // form error message
          // if(this.formErrorMessage) {
          //   this.errorMessage = this.errorMessage + this.createErrorMessage(andConditions[i]);
          // }

          orTrue = this.evaluateExpression(andConditions[i]);
          //orConditions.push(andConditions[i]);
        }

        // exit if any false since it is AND
        if (!orTrue) {
          break;
        }
        // this.errorMessage =  this.errorMessage + ' AND ';
      }
    } else if (expression.indexOf(" OR ") > 0) {
      orConditions = expression.split(" OR ");
      console.log('else if - orConditions: ' + orConditions);

      for (let k = 0; k < orConditions.length; k++) {
        // form error message
        // if(this.formErrorMessage) {
        //   this.errorMessage = this.errorMessage + this.createErrorMessage(orConditions[k]);
        // }

        // exit if any true since it is OR
        if (this.evaluateExpression(orConditions[k])) {
          orTrue = true;
          break;
        }

        // this.errorMessage =  this.errorMessage + ' OR ';
      }
    } else {
      console.log('else - expression: ' + expression);
      // form error message
      // if(this.formErrorMessage) {
      //   this.errorMessage = this.errorMessage + this.createErrorMessage(expression);
      // }

      orTrue = this.evaluateExpression(expression);
    }

    console.log('Expression evaluation status: ' + orTrue);
    return orTrue;
  }

  parseAndCallNestedFunction(funString, secondaryDelimiter) {
    try {
      let fun_name = funString.substring(0, funString.indexOf("("));
      let fun_params = funString.substring(funString.indexOf("(") + 1, funString.indexOf(")"));
      let params = fun_params.split(secondaryDelimiter);

      console.log('parseAndCallNestedFunction:: fun_name: ' + fun_name + ", fun_params: " + fun_params + ", params# " + params.length);

      if (this[fun_name]) {
        let sumVal = this[fun_name](params);
        console.log('parseAndCallNestedFunction:: sumVal: ' + sumVal);
      }
    } catch (err) {
      console.log(err.message);
      return false;
    }
  }

  getItemValue(item) {
    /** If onScreenDisable then based on selected data it will return back else will fetch data from SurveyResponse */
    if (this.onScreenDisable == 'onScreenDisable') {
      let items = item.split('_');
      let response;
      if (this.currentSelectedData.length == 1) {
        if (this.currentSelectedData[0].selected_header == items[1] && this.currentSelectedData[0].selected_value) {
          response = this.currentSelectedData[0].selected_value;
        }
      } else if (items.length == 2 && this.currentSelectedData[0].option_type == "Rating") { /** Rating question type with item like S1_3 */
        this.currentSelectedData.forEach(element => {
          if (element.selected_row == items[1]) {
            response = element.selected_value;
          }
        });
      } else if (items.length == 2 && this.currentSelectedData[0].option_type == "Single choice") { /** Single choice question type with item like S1_B */
        this.currentSelectedData.forEach(sCoiceElement => {
          if (sCoiceElement.selected_header == items[1]) {
            response = sCoiceElement.selected_value;
          }
        });
      } else {
        this.currentSelectedData.forEach(element => {
          if (element.selected_header == items[1] && element.selected_row == items[2]) {
            response = element.selected_value;
          }
        });
      }
      return response;
    } else {
      let surveyResponse = this._session.api.local.get('SurveyResponse');
      // item = item.toUpperCase();
      return surveyResponse[item];
    }
  }

  getItemValues(item) {
    console.log("getItemValues: for item: " + item);
    let surveyResponse = this._session.api.local.get('SurveyResponse');
    let itemValues = [];
    for (let [key, value] of Object.entries(surveyResponse)) {
      if (key.startsWith(item) && value) {
        itemValues.push(value);
      }
    }
    console.log("getItemValues: itemValues: " + itemValues);
    return itemValues;
  }

  getSelectedItems(item) {
    console.log("getSelectedItems: for item: " + item);
    let surveyResponse = this._session.api.local.get('SurveyResponse');
    let selectedItems = [];
    // get the question type
    const questionType = this._session.api.local.get(Config.CONSTANTS.QUESTIONTYPELIST)
    let qType;
    let params = item.split('_');
    qType = this.getQuestionType(params[0]);
    if (['Select all that apply', 'Combination',].indexOf(qType) > -1) {
      if (this.onScreenDisable == 'onScreenDisable') {
        this.currentSelectedData.forEach(element => {
          if (element.selected_value == 1 && element.selected_header == params[1]) {
            selectedItems.push(element.selected_row);
          }
        });
        return selectedItems;
      } else {
        for (let [key, value] of Object.entries(surveyResponse)) {
          if (key.startsWith(item) && value && value == 1) {
            // console.log("getSelectedItems: key: " + key + ", value: " + value);
            selectedItems.push(key.replace(item + '_', ''));
          }
        }
        console.log("getSelectedItems: selectedItems: " + selectedItems);
        return selectedItems;
      }
    } else {
      for (let [key, value] of Object.entries(surveyResponse)) {
        if (key.startsWith(item) && value) {
          const temp = {
            key: key.replace(item + '_', ''),
            value: Number(value)
          }
          selectedItems.push(temp);
        }
      }
      console.log("getSelectedItems: selectedItems: " + selectedItems);
      return selectedItems.filter(el => el && el.key !== 'OTHER');
    }

  }

  getQuestionType(item) {
    let QuestionTypes = this._session.api.local.get('QuestionTypes');
    return QuestionTypes[item];
  }

  getQuestionNumber(question) {
    let QuestionNumberMap = this._session.api.local.get('QuestionNumberMap');
    return QuestionNumberMap[question];
  }

  setItemValue(key, value) {
    console.log("setItemValue:: key - " + key + ", value - " + value);
    let surveyResponse = this._session.api.local.get('SurveyResponse');
    surveyResponse[key.toUpperCase()] = value;
    this._session.api.local.save('SurveyResponse', surveyResponse);
  }

  evaluateExpression(expression) {
    let evalStatus = false;
    expression = expression.trim();
    console.log('evaluating expression: ' + expression);

    // check if the expression is valid
    let result = expression.match(this.pattern);
    if (result) {
      //console.log('The expression is valid!');
      // find operator -
      // let opval = this.operatorPattern.exec(expression);
      let opval = expression.match(this.operatorPattern);
      console.log("opval: " + opval);

      if (opval) {
        opval = opval[0];
        let expressionArray = expression.split(opval);
        console.log("expressionArray: " + expressionArray);
        if (expressionArray && expressionArray.length == 2) {
          // TODO - functions evaluation - COUNT/MAX ..
          let itemVal;
          let funVal = expressionArray[0].trim().match(this.funPattern);
          if (funVal) {
            funVal = funVal[0];
            // for DURATION(), get duration type from input - days, weeks, months, years
            let durationType = null;
            if (funVal.includes("DURATION")) {
              durationType = expressionArray[1].match(this.durationTypePattern);
              console.log("durationType: raw " + durationType);
              if (durationType) {
                expressionArray[1] = expressionArray[1].replace(durationType[0], "").trim();
                console.log("duration value: " + expressionArray[1]);
                durationType = durationType[0].toLowerCase();
              } else {
                durationType = this.defaultDurationType;
              }
              console.log("durationType: " + durationType);
            }
            itemVal = this.checkFunctionAndGetValue(funVal, durationType);
          }

          if (itemVal == null) {
            // find type - 
            //handle - [S5_A_1] * 10 scenarios
            let expData = this.findAndReplaceVarWithData(expressionArray[0].trim());
            console.log("expData: " + expData);
            if (expData && expData !== 'undefined') {
              try {
                itemVal = eval(expData);
              } catch (e) {
                itemVal = null;
                console.log('unable to eval expData: ' + expData);
              }
              console.log("eval itemVal: " + itemVal);
            }
            if (itemVal == null) {
              itemVal = this.checkAndGetItemValue(expressionArray[0].trim());
            }
          }

          // find value -
          if (itemVal || itemVal > -1) {
            let rightVal;
            let rightFunVal = expressionArray[1].trim().match(this.funPattern);
            if (rightFunVal) {
              rightFunVal = rightFunVal[0];
              // for DURATION(), get duration type from input - days, weeks, months, years
              let durationType = null;
              if (rightFunVal.includes("DURATION")) {
                durationType = expressionArray[1].match(this.durationTypePattern);
                console.log("durationType: raw " + durationType);
                if (durationType) {
                  expressionArray[1] = expressionArray[1].replace(durationType[0], "").trim();
                  console.log("duration value: " + expressionArray[1]);
                  durationType = durationType[0].toLowerCase();
                } else {
                  durationType = this.defaultDurationType;
                }
                console.log("durationType: " + durationType);
              }
              rightVal = this.checkFunctionAndGetValue(rightFunVal, durationType);
            }

            // check for multi-value pattern else single value
            let value = rightVal ? rightVal : this.multivalPattern.exec(expressionArray[1].trim());
            if (value && value[0] && value[0] != rightVal) {
              if (this.errorDetail) {
                this.errorDetail = this.errorDetail.replace(expressionArray[1].trim(), value[0]);
              }
              evalStatus = this.checkMultiValue(value, itemVal);
            } else {
              let val = rightVal ? rightVal : expressionArray[1].trim();
              evalStatus = this.checkSingleValue(val, itemVal, opval, expression);
            }

            // set invalid cells if evalStatus is false
            let qType = this.questionType;
            console.log('onScreenRuleExecution: ' + this.onScreenRuleExecution + ', qType: ' + qType + ', evalStatus: ' + evalStatus);
            if (this.onScreenRuleExecution && !evalStatus && qType && (qType == 'Number' || qType == 'Percent' || qType == 'Date')) {
              let varData = expressionArray[0].trim();
              if (varData.indexOf("[") > -1 && varData.indexOf("]") > -1) {
                varData = varData.substring(varData.indexOf("[") + 1, varData.indexOf("]"));
              }
              let col, row;
              let questionParams = varData.split("_");
              if (questionParams && questionParams.length > 1) {
                col = questionParams[1];
                if (questionParams.length > 2) {
                  row = questionParams[2];
                }

                let colIdx = -1;
                this.currentQuestionOptions['columns'].find(function (c, i) {
                  if (c['columnId'] === col) {
                    colIdx = i;
                    return i;
                  }
                });
                console.log('col: ' + col + ', colIdx: ' + colIdx + ', row: ' + row);
                if (colIdx > -1 && row) {
                  this.currentQuestionOptions['rows'].forEach((r) => {
                    let roVal = r[0]['value'];
                    let colRow = r[colIdx];
                    if (roVal && roVal === row && colRow && colRow['valueType'] !== 'Label') {
                      colRow['invalid'] = true;
                    }
                  });
                }
              }
            }
            this.onScreenRuleExecution = false;

            return evalStatus;
          }
        }
      }
    }
    else {
      console.log('Invalid expression, skipping process ..');
    }
    return evalStatus;
  }

  getExpressionData(expression) {
    let itemVal;
    expression = expression.trim();
    console.log('getExpressionData:: expression: ' + expression);

    let result = expression.match(this.pipe_pattern);
    if (result) {
      let funVal = expression.match(this.funPattern);
      if (funVal) {
        funVal = funVal[0];
        // for DURATION(), get duration type from input - days, weeks, months, years
        let durationType = null;
        //if (funVal.includes("DURATION")) {
        // TODO implement with duration type - yera, month ..

        /*durationType = expressionArray[1].match(durationTypePattern);
        console.log("durationType: raw " + durationType);
        if(durationType) {
          durationType = durationType[0].toLowerCase();
        } else {
          durationType = defaultDurationType;
        }
        console.log("durationType: " + durationType);*/
        //}
        itemVal = this.checkFunctionAndGetValue(funVal, durationType);
      } else {
        itemVal = this.checkAndGetItemValue(expression);
      }

      if (!itemVal) {
        itemVal = expression.replace('%', '');
      }
      console.log('getExpressionData:: itemVal: ' + itemVal);
    } else {
      console.log('getExpressionData:: Invalid expression, skipping process ..');
    }
    return itemVal;
  }

  // checkAndReplaceItems(rtExpression) {
  //   let qType = this.getCurrentQuestionDetails()
  //   console.log('checkAndReplaceItems: rtExpression enter: ' + rtExpression);
  //   let item;
  //   let itemVal;
  //   let items = rtExpression.match(this.itemsPattern);
  //   let retVal = rtExpression;
  //   if (items && !retVal.includes('COUNT') && !retVal.includes('DURATION')) {
  //     for (let s = 0; s < items.length; s++) {
  //       item = items[s];
  //       // item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
  //       const checkColumnItems = item.split('_');
  //       if (checkColumnItems.length > 2) {
  //         item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
  //         itemVal = this.getItemValue(item);
  //       } else {
  //         itemVal = this.getMultiCount(item);
  //       }
  //       if (itemVal) {
  //         retVal = retVal.replace(items[s], itemVal);
  //       } else {
  //         retVal = retVal.replace(items[s], "");
  //       }
  //     }
  //     console.log('checkAndReplaceItems: retVal: ' + retVal);
  //   }
  //   if (!retVal.includes('DURATION') && retVal.match(this.arithmaticPattern) && (['Date', 'Combination'].indexOf(qType.questionType) == -1 && qType.combinationTypes !== 'Date And Single choice')) {
  //     try {
  //       retVal = Math.round(eval(retVal));
  //     } catch (err) {
  //       console.log('checkAndReplaceItems: unable to evalute ' + err);
  //       retVal = rtExpression;
  //     }
  //     console.log('checkAndReplaceItems: after eval retVal: ' + retVal);
  //   } else if (retVal.match(this.pipe_pattern) && !retVal.includes('DURATION') && !retVal.includes('COUNT')) {
  //     retVal = 'PIPE' + retVal;

  //     retVal = this.checkFunctionAndGetValue(retVal, '')
  //   } else if (retVal.includes('DURATION')) {
  //     let opval = retVal.match(this.operatorPattern);
  //     if (opval) {
  //       retVal = this.evaluateExpression(retVal)
  //     } else {
  //       retVal = this.evaluateDurationExpression(retVal)
  //     }

  //   } else if (retVal.includes('COUNT')) {
  //     let opval = retVal.match(this.arithmaticPattern);
  //     if (opval) {
  //       opval = opval[0];
  //       let expressionArray = retVal.split(opval);
  //       const count = this.checkFunctionAndGetValue(expressionArray[0], '');
  //       if (count) {
  //         retVal = `${count}${opval}${expressionArray[1]}`
  //       }
  //     } else {
  //       retVal = this.checkFunctionAndGetValue(retVal, '')

  //     }

  //   }
  //   return retVal;
  // }

  checkAndReplaceItems(rtExpression) {
    let qType = this.getCurrentQuestionDetails()
    const exceptCond = rtExpression.match(this.pipe_pattern)
    console.log('checkAndReplaceItems: rtExpression enter: ' + rtExpression);
    let item;
    let itemVal;
    let items = rtExpression.match(this.itemsPattern);
    let pipePattern = rtExpression.match(this.funPattern)
    let retVal = rtExpression;
    if (items && !pipePattern) {
      for (let s = 0; s < items.length; s++) {
        item = items[s];
        item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
        let params = item.split("_");
        if (params && params.length === 2) {
          const temp = this.getSelectedItems(item);
          const colSum = temp.map(el => el.value);
          if (colSum.length > 0) {
            itemVal = colSum.reduce((a, b) => parseInt(a) + parseInt(b), 0);
            console.log("sumVal: " + itemVal);
          }
        } else {
          itemVal = this.getItemValue(item);
        }
        if (itemVal) {
          retVal = retVal.replace(items[s], itemVal);
        } else {
          retVal = retVal.replace(items[s], "");
        }
      }
      if (!retVal.includes('DURATION') && retVal.match(this.arithmaticPattern) && (['Date', 'Combination'].indexOf(qType.questionType) == -1 && qType.combinationTypes !== 'Date And Single choice')) {
        try {
          retVal = Math.round(eval(retVal));
        } catch (err) {
          retVal = rtExpression;
        }
      }
      console.log('checkAndReplaceItems: retVal: ' + retVal);
    } else if (items && pipePattern) {
      let opval = retVal.match(this.operatorPattern);
      if (retVal.includes('DURATION')) {
        retVal = opval ? this.evaluateExpression(retVal) : this.evaluateDurationExpression(retVal);
      } else {
        let opval = retVal.match(this.arithmaticPattern);
        if (opval) {
          let expressionVal;
          let expressionSyntax
          const expression = rtExpression.split(opval);
          if (expression.length > 1) {
            expression.forEach(element => {
              if (element.match(this.funPattern)) {
                expressionVal = this.checkFunctionAndGetValue(element, '')
                retVal = expressionVal ? retVal.replace(element, expressionVal) : retVal.replace(element, '')
              }
            });
            try {
              retVal = Math.round(eval(retVal));
            } catch (err) {
              retVal = rtExpression;
            }
          }
        } else {
          retVal = this.checkFunctionAndGetValue(rtExpression, '')

        }
        // const test = rtExpression.replace(rtExpression, retVal);

      }



    }
    return retVal;
  }

  checkAndGetItemValue(rtExpression) {
    let itemVal: any;
    //let item = itemPattern.exec(rtExpression);
    let item = rtExpression.match(this.itemPattern);
    if (item) {
      item = item[0];
      item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
      console.log('checkAndGetItemValue:: item: ' + item);
      let qType;
      let params = item.split("_");
      if (params && params.length === 2) {
        qType = this.getQuestionType(params[0]);
        itemVal = this.getSelectedItems(item);
      } else {
        itemVal = this.getItemValue(item);
      }
      console.log("checkAndGetItemValue:: itemVal: " + itemVal);
    }
    return itemVal;
  }

  // checkAndGetItemValue(rtExpression) {
  //   let itemVal: any;
  //   //let item = itemPattern.exec(rtExpression);
  //   let item = rtExpression.match(this.itemPattern);
  //   if (item) {
  //     item = item[0];
  //     item = item.substring(item.indexOf("[") + 1, item.indexOf("]"));
  //     console.log('checkAndGetItemValue:: item: ' + item);
  //     // get question type for the given question 
  //     let qType;
  //     let params = item.split("_");
  //     if (params && params.length === 2) {
  //       qType = this.getQuestionType(params[0]);
  //     }

  //     console.log('checkAndGetItemValue:: question: ' + params[0] + ', qType: ' + qType);
  //     if (qType || params[1]) {
  //       // if (qType === 'Select all that apply' || qType === 'Combination' ) {
  //       if ((params && params.length === 2)) {
  //         // itemVal = this.currentSelectedData.filter(res => res['selected_header'] === params[1])
  //         //   .map((data) => data['selected_value'] === 1 ? data['selected_row'] : undefined).filter(x => x);
  //         itemVal = this.getSelectedItems(item);
  //       } else if (qType === 'Number') {
  //         // itemVal = this.currentSelectedData
  //         //   .map((data) => data['selected_header'] === params[1] ? data['selected_value'] : 0).filter(x => x);
  //         itemVal = this.getItemValues(item);
  //       } else if (qType === 'Rating') {
  //         itemVal = this.getItemValue(item);
  //       }
  //     } else {
  //       itemVal = this.getItemValue(item);
  //     }
  //     console.log("checkAndGetItemValue:: itemVal: " + itemVal);
  //   }
  //   return itemVal;
  // }

  checkMultiValue(value, itemVal) {
    let conditionResult = false;
    if (value) {
      value = value[0];
      let checkAll = value.includes("ALL");
      value = value.substring(value.indexOf("[") + 1, value.indexOf("]"));
      if (value) {
        value = value.split(",");
        // check for ALL or IN condition
        if (checkAll) {
          console.log("process ALL value1: " + value);
          conditionResult = this.arrayContainsArray(itemVal, value);
          console.log("Condition Evaluation result : " + conditionResult);
        } else {
          console.log("process IN value1: " + value + ", itemVal : " + itemVal);
          if (itemVal.length > 1) {
            conditionResult = this.arrayContainsAnyArrayElement(value, itemVal)
          } else {
            if (typeof itemVal === 'string' || typeof itemVal === 'number') {
              itemVal = itemVal + '';
            } else {
              itemVal = itemVal[0];
            }
            console.log(itemVal);
            conditionResult = value.includes(itemVal);
          }
          console.log("Condition Evaluation result : " + conditionResult);
        }
      }
    }
    return conditionResult;
  }

  checkSingleValue(singleVal, itemVal, opval, ruleType?) {
    const count = ruleType.includes('COUNT')
    let variableValue = singleVal;
    singleVal = singleVal.toString();
    // check if single val is variable
    console.log("checkSingleValue - single value before: " + singleVal);
    let varSingleValue = this.checkAndGetItemValue(singleVal);
    varSingleValue = (Array.isArray(varSingleValue) && count) ? varSingleValue.length.toString() : varSingleValue;
    singleVal = varSingleValue ? varSingleValue : singleVal;
    singleVal = Array.isArray(singleVal) ? singleVal[0] : singleVal;
    console.log("checkSingleValue - single value after: " + singleVal);
    let conditionResult = false;
    let condition;
    // let value = this.valPattern.exec(singleVal);
    let value = singleVal.toString().match(this.valPattern);
    if (value) {
      value = value[0];
      // remove % symbol 
      value = value.replace('%', '');
      console.log("single value: " + value);
      //replace = with ==
      // if (opval === "=") {
      //   opval = "==";
      // } else if (opval === "<>") {
      //   opval = "!=";
      // }
      // condition = itemVal + opval + value;
      console.log("Evaluate condition: " + itemVal + opval + value);
      itemVal = isNumeric(itemVal) ? +itemVal : itemVal;
      if (Array.isArray(itemVal)) {
        itemVal = itemVal.map(el => el.value).reduce((a, b) => parseInt(a) + parseInt(b));
      }

      value = isNumeric(value) ? +value : value;
      // conditionResult = eval(condition);
      if (this.errorDetail) {
        // console.log('reframe error message - ' + this.errorDetail);
        this.errorDetail = this.errorDetail.replace(variableValue, value);
        // console.log('after reframe error message - ' + this.errorDetail);
      }
      conditionResult = this.compareValues(itemVal, opval, value);
      console.log("Condition Evaluation result : " + conditionResult);
    }
    return conditionResult;
  }

  checkFunctionAndGetValue(funExpression, durationType) {
    let itemVal = null;
    console.log("checkFunctionAndGetValue :: funExpression: " + funExpression + ", durationType: " + durationType);
    // funExpression = funExpression.replace('(', '("').replace(')', '")');
    if (durationType) {
      funExpression = funExpression.replace('(', '(' + durationType + '##');
    }
    console.log("Evaluate function: " + funExpression);
    try {
      //itemVal = eval(funExpression);
      let fun_name = funExpression.substring(0, funExpression.indexOf("(")).trim();
      let fun_params = funExpression.substring(funExpression.indexOf("(") + 1, funExpression.length - 1);
      let params = fun_params.split("##");

      console.log('checkFunctionAndGetValue :: fun_name: ' + fun_name + ", fun_params: " + fun_params + ", params# " + params.length);

      if (this[fun_name]) {
        //itemVal = this[fun_name].apply(this, params); // -> JS syntax
        itemVal = this[fun_name](params);
      }
    } catch (err) {
      console.log(err);
      itemVal = null;
    }
    console.log('checkFunctionAndGetValue :: itemVal: ' + itemVal);
    return itemVal;
  }

  arrayContainsArray(superset, subset) {
    if (0 === subset.length) {
      return false;
    }
    return subset.every(function (value) {
      return (superset.indexOf(value) >= 0);
    });
  }

  arrayContainsAnyArrayElement(mainArr, eleArr) {
    return eleArr.some(r => mainArr.indexOf(r) >= 0);
  }

  MIN(item) {
    item = item[0];
    let minVal = null;
    let exceptionList;
    let isException = item.includes('EXCEPT');
    if (isException) {
      const getExceptionReg = /[(|[]/g
      const exceptValue = item.split('EXCEPT')[1]
      const tempList = exceptValue.match(getExceptionReg);
      exceptionList = exceptValue.replace(tempList, '').split(',')
    }
    console.log("Evaluate MIN for : " + item);
    let items = this.getMultiItems(item);
    if (items) {
      console.log("MIN :: items# " + items.length + ", items : " + items);
      let itemVal;
      //if multi values - compare the values
      if (items.length > 1) {
        let itemsArr = [];
        for (let m = 0; m < items.length; m++) {
          let itm = items[m];
          let val;
          let params = itm.split("_");
          if (params && params.length === 2) {
            val = this.getSelectedItems(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          } else {
            val = this.getItemValue(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          }
          if (val) {
            if (Array.isArray(val)) {
              itemsArr.push(...val);
            } else {
              itemsArr.push(val);
            }
          }
        }
        console.log("MIN :: itemsArr : " + itemsArr);
        if (itemsArr) {
          minVal = Math.min(...itemsArr);
          console.log("minVal: " + minVal);
        }
      }
      //if single value - multi choice question - get min value from user choices
      else {
        items = items[0];
        itemVal = this.checkAndGetItemValue(items);

        if (exceptionList) {
          const exceptionArray = itemVal.filter(el => {
            return exceptionList.indexOf(el.key) == -1
          })
          const temp = exceptionArray.map(el => el.value);
          itemVal = JSON.parse(JSON.stringify(temp))

        }
        if (Array.isArray(itemVal) && itemVal.every(el => typeof (el) == 'object')) {
          itemVal = itemVal.map(el => el.value);
        }
        // itemVal = this.getItemValue(items.substring(items.indexOf("[") + 1, items.indexOf("]")));
        console.log("MIN:: itemVal: " + itemVal);
        minVal = Math.min(...itemVal);
        console.log("minVal: " + minVal);
      }
    }
    return minVal;
  }

  MAX(item) {
    item = item[0];
    let maxVal = null;
    let exceptionList;
    let isException = item.includes('EXCEPT');
    if (isException) {
      const getExceptionReg = /[(|[]/g
      const exceptValue = item.split('EXCEPT')[1]
      const tempList = exceptValue.match(getExceptionReg);
      exceptionList = exceptValue.replace(tempList, '').split(',')
    }
    console.log("Evaluate MAX for : " + item);
    let items = this.getMultiItems(item);
    if (items) {
      console.log("MAX :: items# " + items.length + ", items : " + items);
      let itemVal;
      //if multi values - compare the values
      if (items.length > 1) {
        let itemsArr = [];
        for (let m = 0; m < items.length; m++) {
          let itm = items[m];
          let val;
          let params = itm.split("_");
          if (params && params.length === 2) {
            val = this.getSelectedItems(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          } else {
            val = this.getItemValue(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          }
          if (val) {
            if (Array.isArray(val)) {
              itemsArr.push(...val);
            } else {
              itemsArr.push(val);
            }
          }
        }
        console.log("MAX :: itemsArr : " + itemsArr);
        if (itemsArr) {
          if (Array.isArray(itemsArr) && itemsArr.every(el => typeof (el) == 'object')) {
            itemsArr = itemsArr.map(el => el.value);
          }
          maxVal = Math.max(...itemsArr);
          console.log("maxVal: " + maxVal);
        }
      }
      //if single value - multi choice question - get max value from user choices
      else {
        items = items[0];
        itemVal = this.checkAndGetItemValue(items);
        if (exceptionList) {
          const exceptionArray = itemVal.filter(el => {
            return exceptionList.indexOf(el.key) == -1
          })
          const temp = exceptionArray.map(el => el.value);
          itemVal = JSON.parse(JSON.stringify(temp))
        }
        if (Array.isArray(itemVal) && itemVal.every(el => typeof (el) == 'object')) {
          itemVal = itemVal.map(el => el.value);
        }
        console.log("MAX:: itemVal: " + itemVal);

        maxVal = Math.max(...itemVal);
        console.log("maxVal: " + maxVal);
      }
    }
    return maxVal;
  }

  SUM(item) {
    let sumVal = null;
    item = item[0];
    let exceptionList;
    let isException = item.includes('EXCEPT');
    if (isException) {
      const getExceptionReg = /[(|[]/g
      const exceptValue = item.split('EXCEPT')[1]
      const tempList = exceptValue.match(getExceptionReg);
      exceptionList = exceptValue.replace(tempList, '').split(',')
    }
    let exceptList = item.split('EXCEPT')
    console.log("Evaluate SUM for : " + item);
    let items = this.getMultiItems(item);
    if (items) {
      console.log("SUM :: items# " + items.length + ", items : " + items);
      let itemVal;
      //if multi values - get sum of values
      if (items.length > 1) {
        let itemsArr = [];
        for (let m = 0; m < items.length; m++) {
          let itm = items[m];
          let val;
          let params = itm.split("_");
          if (params && params.length === 2) {
            val = this.getSelectedItems(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          } else {
            val = this.getItemValue(itm.substring(itm.indexOf("[") + 1, itm.indexOf("]")));
          }
          if (val) {
            if (Array.isArray(val)) {
              itemsArr.push(...val);
            } else {
              itemsArr.push(val);
            }
          }
        }
        console.log("SUM :: itemsArr : " + itemsArr);
        if (itemsArr) {
          sumVal = itemsArr.reduce((a, b) => parseInt(a) + parseInt(b), 0);
          console.log("sumVal: " + sumVal);
        }
      }
      //if single value - multi choice question - get sum of value from user choices
      else {
        items = items[0];
        itemVal = this.checkAndGetItemValue(items);
        // itemVal = this.getItemValue(items.substring(items.indexOf("[") + 1, items.indexOf("]")));
        if (exceptionList) {
          const exceptionArray = itemVal.filter(el => {
            return exceptionList.indexOf(el.key) == -1
          })
          const temp = exceptionArray.map(el => el.value);
          itemVal = JSON.parse(JSON.stringify(temp))

        }
        if (Array.isArray(itemVal) && itemVal.every(el => typeof (el) == 'object')) {
          itemVal = itemVal.map(el => el.value);
        }

        console.log("SUM:: itemVal: " + itemVal);
        sumVal = itemVal.reduce((a, b) => parseInt(a) + parseInt(b), 0);
        console.log("sumVal: " + sumVal);
      }
    }
    return sumVal;
  }

  COUNT(item) {
    let countVal = null;
    console.log("Evaluate COUNT for : " + item);
    // let items = this.getMultiItems(item);
    let items: any = this.getMultiCount(item[0]);
    if (items) {
      console.log("COUNT :: items# " + items.length + ", items : " + items);
      let itemVal;
      //if multi values - get sum of values
      if (items.length > 0) {
        // TODO - check if we have such scenario ??
        countVal = items.length;
        console.log("COUNT: multival - TODO - check if we have such scenario!!");
      }
      //if single value - multi choice question - get sum of value from user choices
      // else {
      //   items = items[0];
      //   itemVal = this.getItemValue(items.substring(items.indexOf("[") + 1, items.indexOf("]")));
      //   console.log("COUNT:: itemVal: " + itemVal);
      //   countVal = itemVal.length;
      //   console.log("countVal: " + countVal);
      // }
    }
    return countVal;
  }

  DURATION(params) {
    let durationType = params[0], item = params[1];
    let durationVal = null;
    console.log("Evaluate DURATION for : " + item);
    let items = this.getMultiItems(item);
    if (items) {
      console.log("DURATION :: items# " + items.length + ", items : " + items);
      if (items.length > 0) {
        let firstDate = this.getItemValue(items[0].substring(items[0].indexOf("[") + 1, items[0].indexOf("]")));
        let secondDate;
        // SET 2nd date to CURRENT_DATE
        if (items.length == 1) {
          secondDate = new Date();
        } else {
          secondDate = this.getItemValue(items[1].substring(items[1].indexOf("[") + 1, items[1].indexOf("]")));
        }

        let start: moment.Moment = moment(firstDate);
        let end: moment.Moment = moment(secondDate);
        //durationVal = end.from(start, true);
        // get duration type from input - days, weeks, months, years
        durationVal = end.diff(start, durationType.replaceAll('"', ''));
        console.log("DURATION - durationVal (in " + durationType + " ) " + durationVal);
      }
    }
    return Math.abs(durationVal);
  }


  getMultiItems(item) {
    let items = item.match(this.multiItemsPattern);
    return items;
  }

  convertToString(num) {
    return String.fromCharCode(parseInt(num) + 64)
  }

  getNumberFromSurveyLanguage(inputStr) {
    console.log('getNumberFromSurveyLanguage: inputStr : ' + inputStr + ", surveyLanguage: " + this.surveyLanguage);
    let translatedData;
    if (this.inputLangTranslationList.includes(this.surveyLanguage)) {
      switch (this.surveyLanguage) {
        case 'zh': // Chinese
          translatedData = this.chineseToEnglishNum(inputStr);
          break;
        case 'ja': // Japanese
          translatedData = this.japaneseToEnglishNum(inputStr);
          break;
        case 'ar': // Arabic
          translatedData = this.arabicToEnglishNum(inputStr);
          break;
        default:
          translatedData = inputStr;
      }
    } else {
      translatedData = inputStr;
    }
    console.log('getNumberFromSurveyLanguage: translatedData : ' + translatedData);
    return translatedData;
  }

  chineseToEnglishNum(inputStr) {
    console.log('chineseToEnglishNum: inputStr : ' + inputStr);
    let enNum;
    try {
      enNum = toInteger(inputStr);
      // let enStr = toArabicString(inputStr);
    } catch (err) {
      console.log("error - chineseToEnglishNum: " + err.message);
      enNum = inputStr;
    }
    console.log("chineseToEnglishNum: enNum: " + enNum);
    return enNum;
  }

  japaneseToEnglishNum(inputStr) {
    console.log('japaneseToEnglishNum: inputStr : ' + inputStr);
    let enNum;
    try {
      enNum = ja2num(inputStr);
    } catch (err) {
      console.log("error - japaneseToEnglishNum: " + err.message);
      enNum = inputStr;
    }
    console.log("japaneseToEnglishNum: enNum: " + enNum);
    return enNum;
  }

  arabicToEnglishNum(inputStr) {
    console.log('arabicToEnglishNum: inputStr : ' + inputStr);
    try {
      for (let i = 0; i < 10; i++) {
        inputStr = inputStr.replace(this.arabicNumbers[i], i.toString());
      }
    } catch (err) {
      console.log("error - arabicToEnglishNum: " + err.message);
    }
    console.log("arabicToEnglishNum: enNum: " + inputStr);
    return inputStr;
  }


  ConvertNumbersintoSurveyLanguage(inputStr) {
    console.log('ConvertNumbersintoSurveyLanguage: inputStr : ' + inputStr + ", surveyLanguage: " + this.surveyLanguage);
    let translatedData;
    if (this.inputLangTranslationList.includes(this.surveyLanguage)) {
      switch (this.surveyLanguage) {
        case 'zh': // Chinese
          translatedData = this.EnglishtoChineseNum(inputStr);
          break;
        case 'ja': // Japanese
          translatedData = this.EnglishTojapaneseNum(inputStr);
          break;
        case 'ar': // Arabic
          translatedData = this.EnglishToarabicNum(inputStr);
          break;
        default:
          translatedData = inputStr;
      }
    } else {
      translatedData = inputStr;
    }
    console.log('ConvertNumbersintoSurveyLanguage: translatedData : ' + translatedData);
    return translatedData;
  }

  EnglishtoChineseNum(inputStr) {
    console.log('EnglishtoChineseNum: inputStr : ' + inputStr);
    let enNum;
    try {
      enNum = toChineseNumeral(inputStr);
    } catch (err) {
      console.log("error - EnglishtoChineseNum: " + err.message);
      enNum = inputStr;
    }
    console.log("EnglishtoChineseNum: enNum: " + enNum);
    return enNum;
  }

  EnglishTojapaneseNum(inputStr) {
    console.log('japaneseToEnglishNum: inputStr : ' + inputStr);
    let enNum;
    try {
      enNum = num_ja(inputStr);
    } catch (err) {
      console.log("error - EnglishTojapaneseNum: " + err.message);
      enNum = inputStr;
    }
    console.log("EnglishTojapaneseNum: enNum: " + enNum);
    return enNum;
  }

  EnglishToarabicNum(inputStr) {
    console.log('EnglishToarabicNum: inputStr : ' + inputStr);
    let enNum;
    try {
      enNum = ArabicNumbers(inputStr);

    } catch (err) {
      console.log("error - EnglishToarabicNum: " + err.message);
      enNum = inputStr;
    }
    console.log("EnglishToarabicNum: enNum: " + enNum);
    return enNum;
  }


  getLanguageNotificationError() {
    return this.languageErrorNotification;
  }

  setLanguageNotificationError(data) {
    this.languageErrorNotification = data;
  }

  setNumberEachFieldRange(data) {
    this.numberEachFieldRange = data;
  }
  getNumberEachFieldRange() {
    return this.numberEachFieldRange;
  }

  verifyNumberisBetween(curr, start, end) {
    return (curr - start) * (curr - end) <= 0
  }

  setTerminationSurveyImmediately(data) {
    this.terminateSurveyImmediately = data;
  }
  setTerminationSurvey(data) {
    this.terminateSurvey = data;
  }


  atmost(params, errorDetail) {
    console.log(params);
    const item = params[0];
    const item_val = params[1];
    const expression = params[4].substring(params[4].indexOf('(') + 1, params[4].length - 1);
    this._session.api.local.save(Config.CONSTANTS.ATMOST, true);
    const result = {
      type: 'atmost',
      ruleOn: item,
      field_id: item_val,
      upto_count: Number(expression),
      errorText: errorDetail
    }
    return result;
  }

  atleast(params, errorDetail) {
    console.log(params);
    const item = params[0];
    const item_val = params[1];
    const expression = params[4].substring(params[4].indexOf('(') + 1, params[4].length - 1);
    this._session.api.local.save(Config.CONSTANTS.ATLEAST, true);
    const result = {
      type: 'atleast',
      ruleOn: item,
      field_id: item_val,
      upto_count: Number(expression),
      errorText: errorDetail
    }
    return result;
  }

  choose(params, errorDetail) {
    const item = params[0];
    const item_val = params[1];
    const expression = params[4].substring(params[4].indexOf('(') + 1, params[4].length - 1);
    this._session.api.local.save(Config.CONSTANTS.CHOOSE, true);
    const result = {
      type: 'choose',
      ruleOn: item,
      field_id: item_val,
      upto_count: Number(expression),
      errorText: errorDetail
    }
    return result;
  }

  anchor(params, errorDetail) {
    const type = params[0];
    const test = params[1].split(',')
    const values = [...test];
    // const questRowsOrder = JSON.parse(JSON.stringify(this.currentQuestionOptions['rows']));
    const clone = this.currentQuestionOptions['rows'].slice();
    const questRowsOrder = JSON.parse(JSON.stringify(clone));
    if (type === 'TOP') {
      const valueReverse = values.reverse()
      valueReverse.forEach(selectedEl => {
        let index = this.currentQuestionOptions['rows'].findIndex(el => el[0].value == selectedEl);
        let currElement = this.currentQuestionOptions['rows'][index];
        // questRowsOrder.splice(questRowsOrder.findIndex(el => el[0].value == selectedEl), 1);
        this.currentQuestionOptions['rows'].splice(index, 1);
        this.currentQuestionOptions['rows'].unshift(currElement);
      });
    } else {
      console.log('bottom values==>', values)
      values.forEach(selectedEl => {
        let index = this.currentQuestionOptions['rows'].findIndex(el => el[0].value == selectedEl);
        let currElement = this.currentQuestionOptions['rows'][index];
        this.currentQuestionOptions['rows'].splice(index, 1);
        // questRowsOrder.splice(questRowsOrder.findIndex(el => el[0].value == selectedEl), 1);
        this.currentQuestionOptions['rows'].push(currElement);
      });
      // const nonValue = this.currentQuestionOptions['rows'].findIndex(el => typeof (Number(el[0].value)) != 'number');
      const nonValueIndex = this.currentQuestionOptions['rows'].findIndex(el => el[0].value == 'N/A');
      if (nonValueIndex > -1) {
        const temp = this.currentQuestionOptions['rows'][nonValueIndex];
        this.currentQuestionOptions['rows'].splice(nonValueIndex, 1);
        this.currentQuestionOptions['rows'].push(temp);
      }
    }
  }

  orderAs(params, errorDetail) {
    console.log(params)
    const type = params[0];
    const current = this.getCurrentQuestionDetails();
    if (current && current.OrderedOption && current.OrderedOption.length > 0) {
      const orderAs = current.OrderedOption;
      const orderAsCount = orderAs.length;
      const currentQuesCount = this.currentQuestionOptions['rows'].length;
      let tempArray = [];
      const duplicateArray = this.currentQuestionOptions['rows'].slice();
      // [5]
      orderAs.forEach(el => {
        let index = duplicateArray.findIndex(ele => ele[0].value == el);
        if (index > -1 && el !== 'N/A') {
          tempArray.push(duplicateArray[index]);
        }
      });
      if (tempArray.length !== duplicateArray.length) {
        tempArray = tempArray.concat(duplicateArray)
      }
      this.currentQuestionOptions['rows'] = [...Array.from(new Set(tempArray))];

    }
  }

  setCurrentQuestionDetails(data) {
    this.currentCompleteQuestion = data;
  }

  getCurrentQuestionDetails() {
    return this.currentCompleteQuestion;
  }


  getMultiCount(str) {
    let result;
    if (str == '' || null) {
      return ''
    }
    const selectQuestion = str.substring(str.indexOf("[") + 1, str.indexOf("]"));
    const surveyResponse = this._session.api.local.get('SurveyResponse');
    const currentResponseFilter = [];
    for (let item in surveyResponse) {
      if ((item.toString()).includes(selectQuestion)) {
        let temp = {
          name: item,
          value: Number(surveyResponse[item])
        };
        currentResponseFilter.push(temp)
      }
    }
    result = currentResponseFilter.filter(el => el.value);
    return result;
  }


  PIPEMIN(item) {
    item = item[0];
    let minVal = null;
    if (item) {
      item = item.split(',');
      console.log("Evaluate MIN for : " + item);
      minVal = Math.min(...item);
      console.log("minVal: " + minVal);
      console.log("Evaluate MIN for : " + item);
    }
    return minVal;
  }

  PIPEMAX(item) {
    item = item[0];
    let maxVal = null;
    if (item) {
      item = item.split(',');
      maxVal = Math.max(...item);
    }
    return maxVal;
  }

  PIPESUM(item) {
    let sumVal = null;
    item = item[0];
    if (item) {
      item = item.split(',');
      if (item.length > 0) {
        const valuedFilter = item.filter(el => el)
        sumVal = valuedFilter.reduce((a, b) => parseInt(a) + parseInt(b), 0);
      }
    }
    return sumVal;
  }


  evaluateDurationExpression(expression) {
    let itemVal;
    expression = expression.trim();
    let result = expression.match(this.pipe_pattern);
    let durationType = null;
    if (result) {
      let expressionArray = expression;
      console.log("expressionArray: " + expressionArray);
      let funVal = expressionArray.trim().match(this.funPattern);
      if (funVal) {
        funVal = funVal[0];
        // for DURATION(), get duration type from input - days, weeks, months, years
        if (funVal.includes("DURATION")) {
          durationType = "DAYS";
          console.log("durationType: raw " + durationType);
          if (durationType) {
            // expressionArray[1] = expressionArray[1].replace(durationType[0], "").trim();
            console.log("duration value: " + expressionArray[1]);
            durationType = durationType.toLowerCase();
          } else {
            durationType = this.defaultDurationType;
          }
          console.log("durationType: " + durationType);
        }
        itemVal = this.checkFunctionAndGetValue(funVal, durationType);
      }
      if (itemVal) {
        itemVal = Math.abs(itemVal) + " " + durationType
      }
    } else {
      console.log('Invalid expression, skipping process ..');
    }
    return itemVal;
  }

  updateComputeRule(selectedKey, selectedValue) {
    const surveyResponse = this._session.api.local.get('SurveyResponse');
    const input = {};
    input['surveyKey'] = this.respondentDetails.surveyKey;
    input['questionId'] = this.getCurrentQuestionDetails().questionId
    input['participant_id'] = this.respondentDetails.participant_id;
    input['key'] = selectedKey;
    input['value'] = selectedValue;
    input['version_id'] = this.currentVersionId;
    this.httpLayer.post(Config.SERVICE_IDENTIFIER.computeResponse, input).subscribe(response => {
      if (response && response['status'] === 'success') {
        console.log(response.result);
      }
    });
  }


  dropdown_hide(params) {
    // tslint:disable-next-line: prefer-const
    let item = params[0], item_val = params[1], type = params[2], in_item_val = params[3], expression = params[4];
    let expressionEval;
    item_val = item_val.split(',')
    let newDropdownOption
    expression = type == 'ALL' ? params[3] : expression;
    if (expression) {
      expressionEval = this.parseExpression(expression);
    }
    if (expressionEval) {
      this.currentQuestionOptions.rows.forEach((row, rowInd) => {
        this.currentQuestionOptions.columns.forEach((col, colInd) => {
          if (col['columnId']) {
            row[colInd]['current_row'] = row[0]['value'];
            row[colInd]['current_header'] = col['columnId'];
          }
        });
      });
      console.log(this.currentQuestionOptions.rows);
      const dropdown_options = JSON.parse(JSON.stringify(this.currentQuestionOptions['responseCodes']['[Dropdown]']))
      if (type == 'ALL') {
        newDropdownOption = this.filterFrom2Array(dropdown_options, item_val)
        // const newDropdownOption = dropdown_options.filter(el => {
        //   return !item_val.find(innerEl => innerEl == el.code)
        // });
        console.log(newDropdownOption)
        this.currentQuestionOptions.rows.forEach((row, rowInd) => {
          this.currentQuestionOptions.columns.forEach((col, colInd) => {
            if (col['columnId']) {
              if (row[colInd]['value'] == '[Dropdown]' && row[colInd]['responseCode'] && row[colInd]['responseCode']['[Dropdown]']) {
                row[colInd]['responseCode']['[Dropdown]'] = newDropdownOption;
              }
            }
          });
        });
      } else if (type == 'column') {
        in_item_val = in_item_val.split(',')
        newDropdownOption = this.filterFrom2Array(dropdown_options, item_val)
        in_item_val.forEach(colList => {
          this.currentQuestionOptions.rows.forEach((row, rowInd) => {
            this.currentQuestionOptions.columns.forEach((col, colInd) => {
              if (col['columnId'] && col['columnId'] == colList) {
                if (row[colInd]['value'] == '[Dropdown]' && row[colInd]['responseCode'] && row[colInd]['responseCode']['[Dropdown]']) {
                  row[colInd]['responseCode']['[Dropdown]'] = newDropdownOption;
                }
              }
            });
          });
        });
      } else {
        const [question, columnId, rowValue] = in_item_val.split('_');
        newDropdownOption = this.filterFrom2Array(dropdown_options, item_val)
        this.currentQuestionOptions.rows.forEach((row, rowInd) => {
          this.currentQuestionOptions.columns.forEach((col, colInd) => {
            if (col['columnId'] && col['columnId'] == columnId && row[colInd].current_row == rowValue) {
              if (row[colInd]['value'] == '[Dropdown]' && row[colInd]['responseCode'] && row[colInd]['responseCode']['[Dropdown]']) {
                row[colInd]['responseCode']['[Dropdown]'] = newDropdownOption;
              }
            }
          });
        });
      }
    }

  }

  randomdropdown(params) {
    const item = params;
    const columnRandom = params.indexOf('column');
    const dropdown_options = JSON.parse(JSON.stringify(this.currentQuestionOptions['responseCodes']['[Dropdown]']))
    this.currentQuestionOptions.rows.forEach((row, rowInd) => {
      this.currentQuestionOptions.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          row[colInd]['current_row'] = row[0]['value'];
          row[colInd]['current_header'] = col['columnId'];
        }
      });
    });
    let randomArray;
    if (columnRandom == -1) {
      randomArray = this.shuffleArray(dropdown_options);
      this.currentQuestionOptions.rows.forEach((row, rowInd) => {
        this.currentQuestionOptions.columns.forEach((col, colInd) => {
          if (col['columnId']) {
            if (row[colInd]['value'] == '[Dropdown]' && row[colInd]['responseCode'] && row[colInd]['responseCode']['[Dropdown]']) {
              row[colInd]['responseCode']['[Dropdown]'] = randomArray;
            }
          }
        });
      });
    } else {
      const columnList = params.filter(el => el && el != 'column');
      for (let i = columnList.length - 1; i >= 0; i--) {
        randomArray = this.shuffleArray(dropdown_options);
        this.currentQuestionOptions.rows.forEach((row, rowInd) => {
          this.currentQuestionOptions.columns.forEach((col, colInd) => {
            if (col['columnId'] && col['columnId'] == columnList[i]) {
              if (row[colInd]['value'] == '[Dropdown]' && row[colInd]['responseCode'] && row[colInd]['responseCode']['[Dropdown]']) {
                row[colInd]['responseCode']['[Dropdown]'] = randomArray;
              }
            }
          });
        });

      }

    }
  }

  filterFrom2Array(original, list) {
    return original.filter(el => {
      return !list.find(innerEl => innerEl == el.code)
    });
  }




}
