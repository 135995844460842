import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { QuestionsPreviewService } from '../questions-preview.service';
@Component({
  selector: 'app-dropdown-question',
  templateUrl: './dropdown-question.component.html',
  styleUrls: ['./dropdown-question.component.css']
})
export class DropdownQuestionComponent implements OnInit {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public specifyText = '';
  public response = [];
  public optionSelected = [];
  constructor(
    private _commonServices: CommonServices,
    private commonQstnService: QuestionsPreviewService
  ) { }

  ngOnInit() {
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.optionSelected[el['selected_row']] = true;
          // this.responseSingleSelectColumn(el);
        } else {
          this.mapResponse();
        }
      });
    } else {
      this.createResponseJson();
    }
  }
  dataEmitter() {
    this.emitData.emit(this.response);
  }

  changeDropdownData(optionId, i, value, eachTd) {

    if (eachTd.valueType === 'Label' || eachTd.value !== '[Dropdown]' || eachTd.disable) {

      /** Clicking any part of the table will close date Drop Down */
      this.questionDetails.Options.rows.forEach(element => {
        element.forEach(elem => {
          if (elem.value == 'Date' && elem.openDatePicker) {
            elem['openDropDown'] = false;
          }
        });
      });
      return
    }

    /** Drop Down will trigger when clicking the td */
    this.questionDetails.Options.rows.forEach((element, index) => {
      if (optionId == element[0]['value']) {
        element[i]['openDropDown'] = element[i]['openDropDown'] && element[i]['openDropDown'] === true ? false : true;
      } else {
        element[i]['openDropDown'] = false;
      }
    });

    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    /** find response code value */
    const respInd = this.questionDetails.Options.responseCodes['[Dropdown]'].findIndex((ele) => ele['response'] == value);
    if (Ind > -1 && respInd > -1) {
      this.response[Ind]['selected_value'] = this.questionDetails.Options.responseCodes['[Dropdown]'][respInd]['code'];
      const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
      if (otherspecify_Ind > -1 && this.specifyText) {
        this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
        this.response[otherspecify_Ind]['other_specify'] = true;
      } else if (otherspecify_Ind > -1 && !this.specifyText) {
        this.response[otherspecify_Ind]['other_specify'] = false;
      }
    }
    this.dataEmitter();
    this.optionSelected = [];
  }
  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        /** find response code value */
        const respInd = this.questionDetails.Options.responseCodes['[Dropdown]'].findIndex((ele) => ele['code'] == resp['selected_value'] && resp['option_type'] != 'Single choice');
        if (colInd > -1 && row[0]['value'] == resp['selected_row'] && respInd > -1) {
          row[colInd]['dropdown'] = this.questionDetails.Options.responseCodes['[Dropdown]'][respInd]['response'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if (row[0]['value'] == resp['selected_row']) {
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
      });
    });
  }
  chooseNone(rowIndex, OptionId, i, eachTd) {

    if (eachTd.valueType === 'Label' || eachTd.value !== 'Single choice' || eachTd.disable) {
      this.questionDetails.Options.rows.forEach((response) => {
        response.forEach((resp, ind) => {
          resp['radioSelect'] = false;
        })
      });
      return
    }

    this.questionDetails.Options.rows.forEach((response, index) => {
      if (index == rowIndex && response[i].value == 'Single choice') {
        response[i]['radioSelect'] = true;
      }
    });

    this.optionSelected = [];
    this.createResponseJson();
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    this.dataEmitter();
    this.optionSelected[OptionId] = true;
  }

  /**
   * To create response object for all the existing rows in live survey
   */
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          let optionType = row[colInd]['value'];
          let selected_value;
          row[colInd]['dropdown'] = '';
          if (row[colInd]['valueType'] == 'DROP-DOWN') {
            optionType = row[colInd]['valueType'];
            selected_value = '';
          } else {
            selected_value = 0;
          }
          let disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          this.response.push({ selected_value: selected_value, selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: optionType, disable: disable })
        }
      });
    });
    this.dataEmitter();
  }

  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }
  removeSymbols(data) {
    if (data.indexOf('[') > 0) {
      return data.substring(data.indexOf('[') + 1, data.indexOf(']'));
    }
    return data;
  }

  changeText() {
    const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if (otherspecify_Ind > -1 && this.response[otherspecify_Ind]['selected_value'] && this.specifyText) {
      this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
      this.response[otherspecify_Ind]['other_specify'] = true;
    } else {
      delete this.response[otherspecify_Ind]['otherSpecify_value'];
      delete this.response[otherspecify_Ind]['other_specify'];
    }
  }

  responseSingleSelectColumn(el) {
    this.questionDetails.Options.rows.forEach((row) => {
      if (row[0]['value'] == el['selected_row']) {
        this.questionDetails.Options.columns.forEach((col, colInd) => {
          if (col['columnId'] == el['selected_header']) {
            row[colInd]['radioSelect'] = true;
          }
        });
      }
    });
  }
}
