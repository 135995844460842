import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, OnChanges } from '@angular/core';
import { Options } from '@angular-slider/ngx-slider';
import { CommonServices } from 'src/app/services/common-services.service';
import { QuestionsPreviewService } from '../questions-preview.service';

@Component({
  selector: 'app-slider-question',
  templateUrl: './slider-question.component.html',
  styleUrls: ['./slider-question.component.css']
})
export class SliderQuestionComponent implements OnInit, OnChanges {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public specifyText='';
  public response = [];
  isHideRule = false;
  /** Slider option to define min and max */
  options: Options = {
    floor: 0,
    ceil: 500,
    showSelectionBar: true
  };

  optionsDisabled: Options = {
    floor: 0,
    ceil: 500,
    showSelectionBar: true,
    disabled: true,
  };
  constructor(
    public commonQstnService: QuestionsPreviewService,
    private _commonServices: CommonServices
  ) {
  }

  ngOnChanges(changes: SimpleChanges) {
    console.log(changes);
    console.log('###===>', this.questionDetails);
    this.resetSliderValue();
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      this.mappingResponse();
    } else {
      this.createResponseJson();
    }
    this.setSliderValue(this.questionDetails);
  }

  ngOnInit() {
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      this.mappingResponse();
    } else {
      this.createResponseJson();
    }
    this.setSliderValue(this.questionDetails);
  }

  /**
   * Emit back the response to live survey to get saved
   */
  dataEmitter() {
    this.emitData.emit(this.response);
  }

  /**
   * Function called when a slider value gets changed
   * @param rowIndex  - row index
   * @param i - index of slider
   * @param eachRow  - row value
   * @param value - slider value
   */
  dragSliderFn(i, optionId, value, eachTd) {

    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = eachTd.defaultCell && value == 0 ? '' : value ;
    } 
    this.response.forEach((ele,Ind) => {
      if (ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) && Ind > -1 && this.specifyText){
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
        this.response[Ind]['other_specify'] = true;
      }else if(ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) && Ind > -1 && !this.specifyText){
        this.response[Ind]['other_specify'] = false;
      }
    })
    
    this.dataEmitter();
  }

  /**
 * To create response object for all the existing rows in live survey
 */
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          let disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          let sliderValue = row[colInd]['sliderValue'] > 0 ? row[colInd]['sliderValue'] : '';
          row[colInd]['sliderValue'] = sliderValue;
          row[colInd]['defaultCell'] = true; /** Will keep the 'defaultCell' true and based on it will handle answer check and color of slider-pointer */
          this.response.push({ selected_value: sliderValue, selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['valueType'], disable: disable, defaultCell: true})
        }
      });
    });
    this.dataEmitter();
  }

  mappingResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['sliderValue'] = resp['selected_value'] == '' ? '' : Number(resp['selected_value']);
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if(row[0]['value'] == resp['selected_row']){
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
        if((row[0]['value'] == resp['selected_row']) && resp['selected_value'] === ''){
          row[colInd]['defaultCell'] = true;
        }
      });
    });
    this.questionDetails.Options.rows.forEach(element => {
      element.forEach(el => {
        if (el['valueType'] === 'SLIDER_CATEGORY') {
          const keys = Object.keys(el);
          if (keys.indexOf('sliderValue') == -1 && isNaN(el['slidervalue'])) {
            el['sliderValue'] = el['defaultCell'] ? '' : 0;
          }
        }
      });
    });
  }

  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  setSliderValue(questionDetails) {
    this.isHideRule = false;
    if (questionDetails && questionDetails.Instructions.length > 0 && questionDetails.Instructions[0].rule_type === 'ALLOW') {
      /** Taking range values from rule params including -ve value */
      let ruleParams = questionDetails.Instructions[0].instruction.substring(questionDetails.Instructions[0].instruction.indexOf("(") + 1, questionDetails.Instructions[0].instruction.length - 1);
      let temp = ruleParams.split(",");
      // const temp = (questionDetails.Instructions[0].instruction).toString().match(/\d+/g);
      this.options.floor = temp[0];
      this.options.ceil = temp[1];
      this.optionsDisabled.floor = temp[0];
      this.optionsDisabled.ceil = temp[1];
    }
    /** HIDE Rule for Slider - It will hide the model-value of slider */
    if (questionDetails && questionDetails.Instructions.length > 0) {
      questionDetails.Instructions.forEach(instruction => {
        if (instruction.rule_type === 'HIDE') {
          this.isHideRule = true;
        }
      });
    }
  }

  resetSliderValue() {
    this.options = {
      floor: 0,
      ceil: 500,
      showSelectionBar: true
    };

    this.optionsDisabled = {
      floor: 0,
      ceil: 500,
      showSelectionBar: true,
      disabled: true,
    };
  }

  changeText(){
    this.response.forEach((ele,Ind)=>{
      if (ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) && Ind > -1 && ele['selected_value']){
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
        this.response[Ind]['other_specify'] = true;
      } 
    })
  }

  /**
   * On click of slider the function will trigger and will update 'defaultCell' as false
   * @param i Clicked column id
   * @param rowId Clicked row id
   */
  sliderClicked(i, rowId){
    this.questionDetails.Options.rows.forEach(row => {
      if (row[0].value == rowId) {
        row[i]['defaultCell'] = false;
      }
    });
    /** Updating defaultCell in response to validate answer check in live-survey-component */
    const selectedHeader = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == rowId && ele['selected_header'] == selectedHeader);
    if (Ind > -1) {
      this.response[Ind]['defaultCell'] = false;
    } 
  }

}
