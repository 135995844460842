import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LiveSurveyComponent } from './live-survey/live-survey.component';
import { AuthGuard } from './shared';
import { Logout } from './shared/logout/logout';
import { LiveSurveyOthersComponent } from './live-survey-others/live-survey-others.component';

const routes: Routes = [
    { path: '', redirectTo: 'survey-registration', pathMatch: 'full' },
    {
        path: '',
        loadChildren: './layout/layout.module#LayoutModule',
        canActivate: [AuthGuard]
    },
    { path: 'login', loadChildren: './login/login.module#LoginModule' },
    { path: 'survey-registration', loadChildren: './survey-registration/survey-registration.module#SurveyRegistrationModule' },
    {
        path: 'logout',
        loadChildren: './login/login.module#LoginModule',
        canActivate: [Logout],
    },
    {
        path: 'survey/live/:survey_uuid/:zs_uuid',
        component: LiveSurveyComponent
    },
    {
        path: 'survey/live/:actual_survey_key/:survey_uuid/:zs_uuid',
        component: LiveSurveyComponent
    },
    {
        path: 'survey/others/:zs_uuid',
        component: LiveSurveyOthersComponent
    }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {useHash: true})],
    exports: [RouterModule],
    providers: [Logout]
})
export class AppRoutingModule { }
