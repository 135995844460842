import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LiveSurveyOthersComponent } from './live-survey-others.component';
import { QuestionsPreviewModule } from '../shared/questions-preview/questions-preview.module';
import { NgZorroAntdModule } from 'ng-zorro-antd';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule } from '@ngx-translate/core';

@NgModule({
  imports: [
    CommonModule,
    QuestionsPreviewModule,
    NgZorroAntdModule,
    FormsModule,
    TranslateModule,
    NgSelectModule,
  ],
  declarations: [LiveSurveyOthersComponent]
})
export class LiveSurveyOthersModule { }
