import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
// import { every } from 'underscore';
import { CommonServices } from 'src/app/services/common-services.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { LiveSurveyComponent } from 'src/app/live-survey/live-survey.component';
import { QuestionsPreviewService } from '../questions-preview.service';
import { SessionService } from '../../../services/session-service.service';
import { Config } from '../../../config/config';

@Component({
  selector: 'app-multi-select-question',
  templateUrl: './multi-select-question.component.html',
  styleUrls: ['./multi-select-question.component.css']
})
export class MultiSelectQuestionComponent implements OnInit, OnChanges {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter<object>();
  @Output('questionErrror') questionErrror = new EventEmitter<any>();

  public selectAll: any;
  public multiSelectOptions;
  public response = [];
  public optionSelected = [];
  public OTHER_OPTION = "Other (please specify)".toLowerCase();
  public OTHERS_OPTION = "Others (please specify)".toLowerCase();
  public specifyText = '';
  public liveSurveyComponent;


  constructor(
    private _commonServices: CommonServices,
    private notification: NzNotificationService,
    private questionsPreviewService: QuestionsPreviewService,
    private _session: SessionService,

    // private liveSurveyComponent: LiveSurveyComponent
  ) { }


  ngOnChanges(changes: SimpleChanges) {
    /** For closing date picker while clicking anywhere of the div */
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.responseSingleSelectColumn(el);
          // this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
      this.dataEmitter();
    } else {
      this.createResponseJson();
    }
  }

  ngOnInit() {

    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.responseSingleSelectColumn(el);
          // this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
      this.dataEmitter();
    } else {
      this.createResponseJson();
    }
  }


  selectAnOption(i, eachTd, optionId, eachTr, specify, typeClick?, event?) {
    if (event) {
      event.stopPropagation();
    }
    if (typeClick === 'outerClick' && eachTd.value === 'Single choice') { return };
    // const temp = Object.keys(this.optionSelected);
    // console.log(this.optionSelected);
    // this.optionSelected[temp[0]] = false;
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.singleSelectOptions(row, selected_header);
    });
    console.log(this.questionDetails.Options.rows);
    this.optionSelected = [];
    if (specify) {
      // const specifyInd = this.response.findIndex((ele) => [this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(ele['selected_item'].toLowerCase()) > -1);
      const specifyInd = this.response.findIndex((ele) => ele['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch));
      if (specifyInd > -1 && this.specifyText) {
        this.response[specifyInd]['otherSpecify_value'] = this.specifyText;
      } else {
        // delete this.response[specifyInd]['otherSpecify_value'];
      }
      this.dataEmitter();
      return;
    }
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value'] && ele['selected_header'] == selected_header);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = 0;

    }
    console.log(this.optionSelected);
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (eachTd['checked'] && Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
      /** Other specify add 2 extra keys */
      if (this.response[Ind]['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch)) {
        this.response[Ind]['other_specify'] = true;
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
      }
    } else {
      if (Ind !== -1) {
        this.response[Ind]['selected_value'] = 0;
        eachTd['checked'] = false;
      }
    }
    this.dataEmitter();
  }

  dataEmitter() {
    this.emitData.emit(this.response);
  }
  /**
 * To create response object for all the existing rows in live survey
 */
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          row[colInd]['checked'] = false;
          row[colInd]['header'] = col['columnId'];
          let selectedValue;
          if (row[colInd]['value'] == 'Select all that apply' || row[colInd]['value'] == 'Single choice') {

            selectedValue = 0;
          }
          let disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          this.response.push({ selected_value: selectedValue, selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['value'], disable: disable })
        }
      });
    });
    this.dataEmitter();
  }
  /**
 * To find selected item
 * @param row - row 
 * @returns - selected item value
 */



  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  singleSelectOptions(row, selectedId) {
    const Ind = row.findIndex((ele, Ind) => ele['value'] == 'Single choice' && Ind != 0 && ele['radioSelect'] && ele['header'] && ele['header'] == selectedId);
    if (Ind > - 1) {
      row[Ind]['radioSelect'] = false;
    } else {
      return;
    }
  }

  saveNumberJson(i, value, optionId, eachTd) {
    eachTd['invalid'] = false;
    this.optionSelected = [];
    // if (!value) {
    //   return;
    // }
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }

    /** If a row is already there update in the row else add new row */
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
    }
    this.dataEmitter();
    if (value < 1 || value > 100) {
      eachTd['invalid'] = true;
      let rangeStart = 0, rangeEnd = 100;
      const temp = this.questionsPreviewService.getLanguageNotificationError();
      this.questionErrror.emit(temp['PlsEnterbetween'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeStart) + ' -  ' + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(rangeEnd));
      return;
    }
    if (value && isNaN(value)) {
      value = this.questionsPreviewService.getNumberFromSurveyLanguage(value);
      if (isNaN(value)) {
        const temp = this.questionsPreviewService.getLanguageNotificationError();
        this.questionErrror.emit(temp['PlsEnterNumeric']);
        return;
      } else {
        value = +value;
      }
    } else {
      value = +value;
    }

    if (value < 0) {
      eachTd['number'] = '';
      const temp = this.questionsPreviewService.getLanguageNotificationError();
      this.questionErrror.emit(temp['NegativeNotAllowed']);
    }
    /**get & Check precison */
    if (value) {
      const precision = this._session.api.local.get(Config.CONSTANTS.PRECISION);
      // tslint:disable-next-line: prefer-const
      let itemPrecisionVal = this.countDecimals(value);
      if (itemPrecisionVal > precision) {
        eachTd['invalid'] = true;
        eachTd['number'] = '';
        const temp = this.questionsPreviewService.getLanguageNotificationError();
        this.questionErrror.emit(temp['PrecisionOutofrange'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(precision));
      }
    }
  }



  chooseNone(event, rowIndex, OptionId, colIndex, value, typeClick) {

    if (value.valueType == 'Label' || value.value !== 'Single choice' || value.disable) {
      return;
    };

    /** Radio button will select While clicking the td */
    this.questionDetails.Options.rows.forEach((resp, index) => {
      if (OptionId == resp[0]['value'] && resp[colIndex]['value'] == 'Single choice') {
        resp[colIndex]['radioSelect'] = true;
      } else {
        resp[colIndex]['radioSelect'] = false;
      }
    });

    event.preventDefault();
    console.log(this.questionDetails.Options.rows)
    const selected_header = this.questionDetails.Options.columns[colIndex]['columnId'];
    // value['colId'] = selected_header;
    // console.log(selected_header);
    // console.log(event);
    // this.optionSelected = [];
    // this.optionSelected[OptionId] = true;
    this.deselectColumn(selected_header, OptionId);
    // this.createResponseJson();
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    if (this.response[Ind]['selected_value'] && this.specifyText) {
      this.response[Ind]['other_specify'] = true;
      this.response[Ind]['otherSpecify_value'] = this.specifyText;
    }
    this.dataEmitter();
    // event.stopPropagation();
  }
  /**
     * Triggered on change of text entered
     * @param optionId row index
     * @param i - tr index
     * @param value -  value entered
     */
  saveText(optionId, i, value) {
    this.optionSelected = [];
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
    }
    this.dataEmitter();
  }

  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          /** Check it is number,text or multiselect*/
          row[colInd]['header'] = resp['selected_header'];
          if (resp['selected_value'] == '1') {
            row[colInd]['checked'] = true;
          }
          row[colInd]['enteredValue'] = resp['selected_value'];
          if (resp['other_specify'] && resp['otherSpecify_value']) {
            this.specifyText = resp['otherSpecify_value'];
          }
          if (row[0]['value'] == resp['selected_row']) {
            resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          }
        }
      });
    });
  }
  checkBoxSelect(elem, typeClick, row, event) {
    if ((typeClick === 'outerClick' && elem.value === 'Single choice') || elem.disable) { return };
    if (typeClick === 'outerClick') {
      elem['checked'] = !elem['checked'];
    }
    event.stopPropagation();
  }
  countDecimals(value) {
    if (Math.floor(value) === value) { return 0; }
    return value.toString().split('.')[1].length || 0;
  }


  deselectColumn(selected_header, rowValue) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] == selected_header) {
          row[colInd]['checked'] = false;
        }
        if (col['columnId'] == selected_header && rowValue !== row[0]['value']) {
          row[colInd]['radioSelect'] = false;
        }
      });
    });
    this.response.forEach(element => {
      if (element && element.selected_header == selected_header) {
        element.selected_value = 0;
      }
    });
    this.dataEmitter();
  }

  responseSingleSelectColumn(element) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] === element['selected_header'] && row[0]['value'] === element.selected_row) {
          row[colInd]['radioSelect'] = true;
        }
      });
    });
  }


}
