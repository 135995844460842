import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { differenceInCalendarDays } from 'date-fns';
import { QuestionsPreviewService } from '../questions-preview.service';

@Component({
  selector: 'app-date-question',
  templateUrl: './date-question.component.html',
  styleUrls: ['./date-question.component.css']
})
export class DateQuestionComponent implements OnInit {
  public dateFormat = 'dd/MM/yyyy';
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public specifyText = '';
  @Input() questionDetails: any;
  @Input() clickedInsideThePage: boolean;
  @Output('onDataEmit') emitData = new EventEmitter();
  public response = [];
  public today = new Date();
  public optionSelected = [];
  clickedOnDatePickerTd = false;
  constructor(
    public commonQstnService: QuestionsPreviewService,
    private _commonServices: CommonServices
  ) { }

  ngOnChanges(changes: SimpleChanges) {
    /** For closing date picker while clicking anywhere of the div */
    if (changes.clickedInsideThePage && (changes.clickedInsideThePage.currentValue != changes.clickedInsideThePage.previousValue) && !this.clickedOnDatePickerTd) {
      this.questionDetails.Options.rows.forEach(element => {
        element.forEach(elem => {
          if (elem.value == 'Date' && elem.openDatePicker) {
            elem['openDatePicker'] = false;
          }
        });
      });
    }
    this.clickedOnDatePickerTd = false;
  }

  ngOnInit() {
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      // /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
    } else {
      this.createResponseJson();
    }
  }

  dataEmitter() {
    this.emitData.emit(this.response);
  }

  changeText() {
    const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if (otherspecify_Ind > -1 && this.response[otherspecify_Ind]['selected_value'] && this.specifyText) {
      this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
      this.response[otherspecify_Ind]['other_specify'] = true;
    } else {
      delete this.response[otherspecify_Ind]['otherSpecify_value']
      delete this.response[otherspecify_Ind]['other_specify']
    }
    this.dataEmitter();
  }

  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (row[colInd]['parseStatus'] && row[colInd]['parseStatus'] == 'Success' && row[colInd]['parsedInstruction'].includes('pipe') && row[colInd]['value']) {
          row[colInd]['value'] = this.formatDate(row[colInd]['value'])
          row[colInd]['date'] = this.convert(row[colInd]['value']);
        }

        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          console.log(resp['selected_value'])
          // row[colInd]['date'] = this.getDateFormatted(resp['selected_value']);
          row[colInd]['date'] = resp['selected_value'];
          console.log(row[colInd]['date']);

        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if (row[0]['value'] == resp['selected_row']) {
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
      });
    });
  }

  chooseNone(rowIndex, OptionId, eachTd) {

    if (eachTd.valueType === 'Label' || eachTd.value !== 'Single choice' || eachTd.disable) { return }

    this.optionSelected = [];
    this.createResponseJson();
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    this.dataEmitter();
    this.optionSelected[OptionId] = true;
  }

  onDateChange(optionId, i, eachTd) {
    if (eachTd['disable']) { return }
    /** Td is not Single choice then return */
    if (eachTd.value === 'Single choice' || eachTd.valueType === 'Label') {
      /** Clicking any part of the table will close date picker */
      this.questionDetails.Options.rows.forEach(element => {
        element.forEach(elem => {
          if (elem.value == 'Date' && elem.openDatePicker) {
            elem['openDatePicker'] = false;
          }
        });
      });
      return
    }
    this.clickedOnDatePickerTd = true; // For closing date picker
    /** Date picker will trigger when clicking the td */
    this.questionDetails.Options.rows.forEach((element, index) => {
      if (optionId == element[0]['value']) {
        this.clickedOnDatePickerTd = element[i]['openDatePicker'] && element[i]['openDatePicker'] === true ? false : true; // For closing date picker
        element[i]['openDatePicker'] = element[i]['openDatePicker'] && element[i]['openDatePicker'] === true ? false : true;
      } else {
        element[i]['openDatePicker'] = false;
      }
    });

    let value = eachTd['date'];
    console.log(this.convert(value));
    /** Remove no from response */
    this.optionSelected = [];
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = this.convert(value);
    }
    const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if (otherspecify_Ind > -1 && this.specifyText && value) {
      this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
      this.response[otherspecify_Ind]['other_specify'] = true;
    } else if (otherspecify_Ind > -1 && !this.specifyText) {
      this.response[otherspecify_Ind]['other_specify'] = false;
      this.response[otherspecify_Ind]['otherSpecify_value'] = ''
    }
    this.dataEmitter();
  }

  /**
   * To create response object for all the existing rows in live survey
   */
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      let inlinePipe;
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        inlinePipe = false;
        if (col['columnId']) {
          row[colInd]['date'] = '';
          let disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          if (row[colInd]['parseStatus'] && row[colInd]['parseStatus'] == 'Success' && row[colInd]['parsedInstruction'].includes('pipe') && row[colInd]['value']) {
            row[colInd]['value'] = this.formatDate(row[colInd]['value']);
            row[colInd]['date'] = this.convert(row[colInd]['value']);
            inlinePipe = true;
          }
          if (row[colInd]['value'] == 'Single choice') {
            this.response.push({ selected_value: 2, selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['value'], disable: disable })
          } else {
            this.response.push({ selected_value: row[colInd]['date'], selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: inlinePipe ? 'Date' : row[colInd]['value'], disable: disable })
          }
        }
      });
    });
    this.dataEmitter();
  }


  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }

  disabledDate = (current: Date): boolean => {
    // Can not select future days
    return differenceInCalendarDays(current, this.today) > 0;
  };


  // getDateFormatted(date) {
  //   if (typeof (date) === 'string' && date.indexOf('T') > -1) {
  //     const dateTimeList = date.split('T');
  //     if (dateTimeList.length > 0) {
  //       const dateList = dateTimeList[0].split('-');
  //       if (dateList.length > 2) {
  //         return dateList[0] + '/' + dateList[2] + '/' + dateList[1];
  //       } else {
  //         return '';
  //       }
  //     }
  //   }
  // }

  convert(str) {
    if (str == null || '') {
      return ''
    } else {
      let date = new Date(str),
        mnth = ("0" + (date.getMonth() + 1)).slice(-2),
        day = ("0" + date.getDate()).slice(-2);
      return [date.getFullYear(), mnth, day].join("-");
    }
  }

  formatDate(str) {
    if (str == null || '') {
      return '';
    } else {
      let date = new Date(str),
        month = ('0' + (date.getMonth() + 1)).slice(-2),
        day = ('0' + date.getDate()).slice(-2);
      return [day, month, date.getFullYear()].join('/');
    }
  }

}

