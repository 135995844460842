import { Component, Inject, OnInit } from '@angular/core';
import { SessionService } from '../services/session-service.service';
import { Config } from '../config/config';
import { HttpLayerService } from '../services/http-layer.service';
import { DOCUMENT } from '@angular/common';
import { NzNotificationService } from 'ng-zorro-antd';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { QuestionsPreviewService } from '../shared/questions-preview/questions-preview.service';
import { TranslateService } from '@ngx-translate/core';


@Component({
  selector: 'app-live-survey-others',
  templateUrl: './live-survey-others.component.html',
  styleUrls: ['./live-survey-others.component.css']
})
export class LiveSurveyOthersComponent implements OnInit {

  private BASE_INPUT: any;
  public isbackToSurvey = false;
  public routerParams: any;
  public surveyStatus = "";
  public gl_participantId: any;
  public gl_surveyKey: any;
  public checkGenericLink = false;
  public checkVendor = false;
  public checkRedirectURL: any = false;
  public redirectURLCompleted: any;
  public redirectURLQuota: any;
  public redirectURLTerminated: any;
  public ipAddress: any;
  public respondentDetails;
  public ribbon_message;
  public translateLang: any;
  public isVisible = false;
  public action = "";
  public NEXT_SECTION = "next_section";
  public formData = {
    files: []
  }


  constructor(
    private _session: SessionService,
    private httpLayer: HttpLayerService,
    private notification: NzNotificationService,
    private http: HttpClient,
    private route: ActivatedRoute,
    private questionsPreviewService: QuestionsPreviewService,
    public translate: TranslateService,
    @Inject(DOCUMENT) private document: any

  ) {
    translate.setDefaultLang("en");
    this.translateLanguage();
  }

  ngOnInit() {
    this.BASE_INPUT = JSON.stringify(
      this._session.api.local.get(Config.CONSTANTS.SELECTED_PROJECT)
    );
    try {
      this.httpLayer.hideLoader();
      this.getIPAddress();
      /** Remove main-page-loader */
      const el = this.document.getElementsByClassName("main-page-loader")[0];
      el.style.display = "none";
      /**Set default values on load - first time when we open live survey*/
      this.isbackToSurvey = false;
      this._session.api.local.save(Config.CONSTANTS.RANGE_START, 0);
      this._session.api.local.save(Config.CONSTANTS.NUM_RANGE_END, 9999);
      this._session.api.local.save(Config.CONSTANTS.PER_RANGE_END, 100);
      this._session.api.local.save(Config.CONSTANTS.PRECISION, 0);
      this._session.api.local.save(Config.CONSTANTS.TEXT_START, 10);
      this._session.api.local.save(Config.CONSTANTS.TEXT_END, 500);
      this._session.api.local.save(Config.CONSTANTS.MINIMUM_RESPONSE, 1);
      this._session.api.local.save(Config.CONSTANTS.HOLD_SCREEN_DURATION, 30);
      this._session.api.local.save(Config.CONSTANTS.DEFAULT_VALUE, null);
      this._session.api.local.save(Config.CONSTANTS.ATMOST, false);
      this._session.api.local.save(Config.CONSTANTS.ATLEAST, false);
      this._session.api.local.save(Config.CONSTANTS.CHOOSE, false);
      // this.initialLoad = true;
      this._session.api.local.save("QuestionTypes", new Map());
      this._session.api.local.save("QuestionNumberMap", new Map());

      this.routerParams = this.route.snapshot.params;
      this.fetchSurveyStatus();
    } catch (e) {
      console.log(e);
    }
  }

  // Function for fetching the survey status
  fetchSurveyStatus() {
    try {
      const payLoad = {
        zs_uuid: this.routerParams.zs_uuid || '',
        survey_uuid: 'others'
      }
      this.httpLayer
        .post(Config.SERVICE_IDENTIFIER.FETCH_SURVEY_STATUS_OTHERS, payLoad)
        .subscribe((response) => {
          if (response["status"] === "success") {
            if (response["result"] == null) {
              this.notification.warning('', "Check survey key and PID");
            }
            // this.surveyStatus = response["result"]["status"];
            this.surveyStatus = response["result"]["survey_status"];
            this.ribbon_message = response['result']['ribbon_message'];
          }
        });
    } catch (error) {
      console.log(error);
    }
  }

  // Function for hadling the images on upload
  fileChange(event) {
    try {
      // console.log(event);
      const fileList: FileList = event.target.files;
      const self = this;
      const ValidImageTypes = ['image/jpg', 'image/jpeg', 'image/png'];
      if (fileList.length > 0) {
        this.formData.files = [];
        let img_data = {};
        for (let i = 0; i < fileList.length; i++) {
          let reader: FileReader = new FileReader();
          let file: File = fileList[i];
          if (file && file.size >= 2000000) {
            this.notification.error('error', 'File size exceeds the maximum limit of 2 MB');
            this.formData.files = [];
            return;
          }
          reader.onload = function (frEvent) {
            let imgCOntent: any = frEvent.target['result'];
            if (imgCOntent.length > 0 && ValidImageTypes.indexOf(file.type) > -1) {
              img_data = {};
              img_data['file_name'] = file.name;
              img_data['data'] = imgCOntent.split(',')[1];
              self.formData.files.push(img_data);
              // (document.getElementById('uploadImage') as HTMLInputElement).disabled = false;
            }
          };
          file = fileList[i];
          reader.readAsDataURL(file);
        }
      }
    } catch (error) {
      console.log(error);
    }

  }

  // Function to save the images and proceed to next screen
  checkData() {
    try {
      if (this.formData && this.formData.files.length > 0) {
        if (this.formData.files.length > 2) {
          this.notification.error('Error', 'Please select 2 Images');
          return;
        }
        const input = {
          files: this.formData.files,
          pid: this.routerParams.zs_uuid
        };
        this.httpLayer.post(Config.SERVICE_IDENTIFIER.saveImages, input).subscribe(response => {
          if (response && response['status'] === 'success') {
            this.notification.success('Success', response.result.message || 'Images uploaded successfully');
            this.fetchSurveyStatus();
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  getIPAddress() {
    this.http.get('http://api.ipify.org/?format=json').subscribe((res: any) => {
      this.ipAddress = res.ip;
    });
  }

  translateLanguage() {
    this.httpLayer.get(Config.CONFIG_IDENTIFIER.LANGUAGE_JSON).subscribe(response => {
      if (response) {
        this.translateLang = response;
      }
    });
  }
}
