import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { QuestionsPreviewService } from '../questions-preview.service';
@Component({
  selector: 'app-rating-question',
  templateUrl: './rating-question.component.html',
  styleUrls: ['./rating-question.component.css']
})
export class RatingQuestionComponent implements OnInit {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public OTHER_OPTION = 'other (please specify)'.toLowerCase();
  public OTHERS_OPTION = 'others (please specify)'.toLowerCase();
  public specifyText = '';

  public response = [];
  constructor(
    private _commonServices: CommonServices,
    private commonQstnService: QuestionsPreviewService
  ) { }

  ngOnInit() {
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      this.dataEmitter();
      this.mappingResponse();
    } else {
      this.createResponseJson();
    }
  }

  /**
   * Emit back the response to live survey to get saved
   */
  dataEmitter() {
    this.emitData.emit(this.response);
  }
  /**
  * Function called when a rating value is changed
  * @param rowIndex  - row index
  * @param i - index of slider
  * @param eachRow  - row value
  * @param value - slider value
  */
  changeOption(i, optionId, tdInd, eachTd) {

    if(eachTd.valueType === 'Label' || eachTd.disable) { return }

    /** Radio button will select While clicking the Td */
    this.questionDetails.Options.rows.forEach( (response) => {
      if (optionId == response[0]['value']) {
        response.forEach((resp, ind) => {
          if(ind == i){
            resp['selectedValue'] = true;
          } else{
            resp['selectedValue'] = false;
          }
        })
      }
    });

    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = tdInd - 1;
      this.response[Ind]['selected_header'] = selected_header;
      const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
      if (otherspecify_Ind > -1 && this.specifyText) {
        this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
        this.response[otherspecify_Ind]['other_specify'] = true;
      } else if (otherspecify_Ind > -1 && !this.specifyText) {
        this.response[otherspecify_Ind]['other_specify'] = false;
      }
    }
    this.dataEmitter();
  }

  /**
* To create response object for all the existing rows in live survey
*/
  createResponseJson() {
    this.response = [];
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.response.push({ selected_value: '', selected_item: selectedItem, selected_header: '', selected_row: row[0]['value'], option_type: 'Rating' })
    });
    this.dataEmitter();
  }

  mappingResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['selectedValue'] = resp['selected_value'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if(row[0]['value'] == resp['selected_row']){
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
      });
    });
  }

  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }



  ratingOtherSpecify() {
    const otherspecify_Ind = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch));
    if (otherspecify_Ind > -1 && this.response[otherspecify_Ind]['selected_value']) {
      this.response[otherspecify_Ind]['otherSpecify_value'] = this.specifyText;
      this.response[otherspecify_Ind]['other_specify'] = true;
    }
  }

}
