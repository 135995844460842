import { environment } from '../../environments/environment';
export class Config {
  /* URL AND SERVICE LIST */
  private static get API_END_POINT(): string { return environment.API_BASE; }
  private static get LOCAL_END_POINT(): string { return './assets/config-data/'; }

  public static SERVICE_IDENTIFIER = {

    LOGIN: Config.API_END_POINT + 'userLogin',

    /* DASHBOARD */
    DASHBOARD_DATA: Config.API_END_POINT + 'dashboardProject',
    PROJECT_DROPDOWN: Config.API_END_POINT + 'fetch_project_metadata',
    DELETE_PROJECT: Config.API_END_POINT + 'deleteVersion',
    CHECK_VERSION: Config.API_END_POINT + 'fetchbackendversion',
    FETCH_NOTIFICATIONS: Config.API_END_POINT + 'getNotification',
    UPDATE_NOTIFICATIONS: Config.API_END_POINT + 'updateNotification',

    /* PROJECT SUMMARY */
    SERVICE_SUMMARY: Config.API_END_POINT + 'fetchProject',
    SERVICE_CREATE_PROJECT: Config.API_END_POINT + 'createProject',
    SERVICE_EDIT_PROJECT: Config.API_END_POINT + 'editProject',
    SAP_PROJECT_DETAILS: Config.API_END_POINT + 'projectDetails',

    /* SURVEY DESIGN */
    FETCH_SETTINGS_LIST: Config.API_END_POINT + 'get_global_instructions_list',
    FETCH_SETTINGS: Config.API_END_POINT + 'get_global_instructions',
    SAVE_SETTINGS: Config.API_END_POINT + 'store_global_instructions',
    SURVEY_METADATA: Config.API_END_POINT + 'metadata_parser',
    SURVEY_STORE_INSTRUCTION: Config.API_END_POINT + 'store_instructions',
    SURVEY_REVIEW: Config.API_END_POINT + 'word_parser',
    STORE_SURVEY: Config.API_END_POINT + 'store_parser',
    SURVEY_MISMATCH: Config.API_END_POINT + 'survey_mismatch_export',
    MESSAGE_QUESTIONS_PREVIEW: Config.API_END_POINT + 'drop_down_preview',
    FETCH_MESSAGE_QUESTIONS: Config.API_END_POINT + 'fetchMessageQuestions',

    /* DATA LOAD */
    FILE_UPLOAD: Config.API_END_POINT + 'uploadDocuments',
    DELETE_SPSS: Config.API_END_POINT + 'delete_spss',
    MISMATCH_DATA: Config.API_END_POINT + 'data_load_summary',
    DATA_LOAD_MISMATCH: Config.API_END_POINT + 'data_load_export',
    DATA_LOAD_MISMATCH_DOWNLOAD: Config.API_END_POINT + 'data_load_excel_download',
    FETCH_FIELD_ARRAY: Config.API_END_POINT + 'fetchFieldArray',
    DATA_LOAD_EXPORT_METADATA: Config.API_END_POINT + 'export_metadata',

    /* DATA DEFINITION */
    FETCH_SEGMENTATION: Config.API_END_POINT + 'fetch_segmentation_data',
    SAVE_SEGMENTATION: Config.API_END_POINT + 'save_segmentation_data',
    DATA_DEFINITION: Config.API_END_POINT + 'data_definition',

    /* DATA GRID */
    SCHEMA: Config.API_END_POINT + 'spss_schema',
    GRID_DATA: Config.API_END_POINT + 'spss_parser',
    SPSS_PARSER: Config.API_END_POINT + 'spss_parser',
    QC_CHECK: Config.API_END_POINT + 'data_validation_status',
    DATA_LOAD_DELETE: Config.API_END_POINT + 'delete_response_history',
    STORE_PARSER: Config.API_END_POINT + 'store_parser',

    /** LIVE SURVEY */
    FETCH_PARTICIPANT_DETAILS: Config.API_END_POINT + 'IndividualParticipants',
    FETCH_QUESTION_DETAILS: Config.API_END_POINT + 'SurveyQuestions',
    SAVE_RESPONSE: Config.API_END_POINT + 'SaveResponse',
    SURVEY_RESPONSE: Config.API_END_POINT + 'ParticipantResponse',
    FETCH_SURVEY_STATUS: Config.API_END_POINT + 'fetch_survey_status',
    FETCH_GENERIC_SURVEY: Config.API_END_POINT + 'FetchFirstPage',

    /** Participant Details */
    UPLOAD_EXCEL: Config.API_END_POINT + 'uploadExcelDocuments',
    SAVE_PARTICIPANTS: Config.API_END_POINT + 'saveParticipants',
    FETCH_PARTICIPANTS: Config.API_END_POINT + 'fetchParticipants',
    UPDATE_LIVESURVEY_STATUS: Config.API_END_POINT + 'update_livesurvey_status',
    UPDATE_SURVEY_STATUS: Config.API_END_POINT + 'update_survey_status',
    UPLOAD_MESSAGE: Config.API_END_POINT + 'uploadMessageDocuments',

    /** Generic Link */
    GENERIC_LINK: Config.API_END_POINT + 'create_generic_link',


    /** Reports */
    DOWNLOAD_AS_SAV: Config.API_END_POINT + 'generate_sav_response_file',
    DOWNLOAD_AS_EXCEL: Config.API_END_POINT + 'generate_excel_response_file',
    DOWNLOAD_PARTICIPANT_TEMPLATE: Config.API_END_POINT + 'generate_save_excel_participants',
    DOWNLOAD_PARTCIPANTS: Config.API_END_POINT + 'generate_excel_response_participants',
    LOAD_PARTCIPANT_STATUS: Config.API_END_POINT + 'load_participant_status',
    EXPORT_FILE: Config.API_END_POINT + 'export_file',
    GENERATE_EXCEL_REPORT: Config.API_END_POINT + 'generate_excel_participant_report',

    // users List
    GET_USER_LISTS: Config.API_END_POINT + 'fetchUserData',
    ADD_USERS: Config.API_END_POINT + 'AddUser',
    EDIT_USERS: Config.API_END_POINT + 'editUser',
    DELETE_USERS: Config.API_END_POINT + 'deleteUser',
    FETCH_QUESTION_ID: Config.API_END_POINT + 'fetchquestionID',
    PUBLISH_RESPONSE_TO_S3: Config.API_END_POINT + 'publish_response_to_s3',

    /** Multi language Translation */
    UPLOAD_MULTI_DOCUMENTS: Config.API_END_POINT + 'uploadTranslationDocuments',
    MULTI_TAB_PREVIEW: Config.API_END_POINT + 'translation_preview',
    LOAD_MULTI_TAB: Config.API_END_POINT + 'load_translations',
    FETCH_REGION: Config.API_END_POINT + 'fetchregion',
    FETCH_LANGUAGE: Config.API_END_POINT + 'fetchlanguage',
    participant_login: Config.API_END_POINT + 'participant_login',
    save_translation_settings: Config.API_END_POINT + 'save_translation_settings',
    fetch_translation_settings: Config.API_END_POINT + 'fetch_translation_settings',
    completed_question: Config.API_END_POINT + 'completedquestion',
    changePassword: Config.API_END_POINT + 'changePassword',
    backToSurveyLink: Config.API_END_POINT + 'backto',
    QuotaQuestionDropdown: Config.API_END_POINT + 'fetch_question_quota',
    QuotaOptionsDropdown: Config.API_END_POINT + 'fetch_option_quota',
    saveQuota: Config.API_END_POINT + 'save_quota',
    fetchQuota: Config.API_END_POINT + 'fetch_quota',
    deleteQuota: Config.API_END_POINT + 'delete_quota',
    fetchQuestionListType: Config.API_END_POINT + 'fetch_question_qid_type',
    computeResponse: Config.API_END_POINT + 'compute_response',

    //** Survey Registration */
    getUserOTP : Config.API_END_POINT + 'get_user_otp',
    saveUsers : Config.API_END_POINT + 'saveUsers',
    getUserCount : Config.API_END_POINT + 'get_user_count',

    //**For uploading the images in surevey generator for OTHERS */
    saveImages: Config.API_END_POINT + 'uploadImages',
    FETCH_SURVEY_STATUS_OTHERS: Config.API_END_POINT + 'fetch_survey_status_others'
  };

  public static CONFIG_IDENTIFIER = {
    PROJECT_CONFIG: Config.LOCAL_END_POINT + 'config-project-form.json',
    SURVEY_CONFIG: Config.LOCAL_END_POINT + 'config-survey-design.json',
    SIDENAV: Config.LOCAL_END_POINT + 'config-sidenav.json',
    LANGUAGE_JSON: Config.LOCAL_END_POINT + 'language.json',
    MESSAGE_SINGLE_TYPE_JSON: Config.LOCAL_END_POINT + 'slider-test.json',
    QuotaDropdown: Config.LOCAL_END_POINT + 'quota-data.json',
    QuotaOptionRows: Config.LOCAL_END_POINT + 'quota-option.json',
    QUOTA_REPORT: Config.LOCAL_END_POINT + 'quota-reports.json'

  }

  public static CONSTANTS = {
    APP_NAME: 'Survey Automation Tool',
    VERSION: '1.0.0',

    USER_SESSION: 'mrUserSession',
    USER_IDENTIFIER: 'mrUserId',
    USER_NAME: 'userName',
    USER_ROLE: 'userRole',
    USER_CATEGORY: 'userCategory',
    SELECTED_PROJECT: 'mrProjectSelected',
    MR_SLIDES: 'mrSlides',
    SELECTED_WAVE: 'mrWaveSelected',
    WAVE_LIST: 'mrWaveList',
    WAVE_SELECTION: 'mrWaveSelection',

    ANALYSIS_GROUP: 'Analysis Group',
    SEGMENTATION: 'Segmentation Codes',
    RESPONSE_CODE: 'Response Codes',
    ROLLED_SEGMENT: 'Rolled Segment',

    ANALYSIS_GROUP_KEY: 'analysisGroup',
    SEGMENTATION_KEY: 'segmentation',
    RESPONSE_CODE_KEY: 'responseCodes',

    BASE_QUESTION: 'Base question',

    SUCCESS_ALERT_TIMEOUT: 1000,
    RANGE_START: 'RANGE_START',
    NUM_RANGE_END: 'NUM_RANGE_END',
    PER_RANGE_END: 'PER_RANGE_END',
    PRECISION: 'PRECISION',
    TEXT_START: 'TEXT_START',
    TEXT_END: 'TEXT_END',
    DATE_START: 'DATE_START',
    DATE_END: 'DATE_END',
    MINIMUM_RESPONSE: 'MINIMUM_RESPONSE',
    HOLD_SCREEN_DURATION: 'HOLD_SCREEN_DURATION',
    DEFAULT_VALUE: 'DEFAULT_VALUE',
    START_QID: 'START_QID',
    END_QID: 'END_QID',
    COUNTRY: 'COUNTRY',
    LANGUAGE: 'LANGUAGE',
    ATMOST: 'ATMOSTENABLE',
    ATLEAST: 'ATLEASTENABLE',
    CHOOSE: 'CHOOSEENABLE',
    QUESTIONTYPELIST: 'Question_type_list'
  };

  public static LOGIN_KEYS: any = {
    USER_ROLE: 'user_role',
    DATA: 'data'
  };

  public static languageList = [
    { code: 'en', label: 'English' },
    { code: 'fr', label: 'French' },
    { code: 'de', label: 'German' },
    { code: 'it', label: 'Italian' },
    { code: 'es', label: 'Spanish' },
    { code: 'pt', label: 'Portuguese' },
    { code: 'ru', label: 'Russian' },
    { code: 'zh', label: 'Chinese' },
    { code: 'zh', label: 'Cantonese' },
    { code: 'ja', label: 'Japanese' },
    { code: 'nl', label: 'Dutch' },
    { code: 'ar', label: 'Arabic' },
    { code: 'el', label: 'Greek' },
    { code: 'tr', label: 'Turkish' },
    { code: 'he', label: 'Hebrew' },
    { code: 'pl', label: 'Polish' },
    { code: 'da', label: 'Danish' },
    { code: 'ko', label: 'Korean' },
    { code: 'hi', label: 'Hindi' }
  ];
}
