import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { textDef } from '@angular/core/src/view';
import { QuestionsPreviewService } from '../questions-preview.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { SessionService } from '../../../services/session-service.service';
import { Config } from '../../../config/config';
import { CommonServices } from 'src/app/services/common-services.service';
import { LiveSurveyComponent } from 'src/app/live-survey/live-survey.component';
import { TranslateService } from '@ngx-translate/core';
import { element } from '@angular/core/src/render3/instructions';
@Component({
  selector: 'app-number-question',
  templateUrl: './number-question.component.html',
  styleUrls: ['./number-question.component.css']
})
export class NumberQuestionComponent implements OnInit {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter<object>();
  @Output('questionErrror') questionErrror = new EventEmitter<any>();
  public response = [];
  public optionSelected = [];
  public OTHER_OPTION = 'Other (please specify)'.toLowerCase();
  public OTHERS_OPTION = 'Others (please specify)'.toLowerCase();
  public specifyText = '';

  constructor(
    private notification: NzNotificationService,
    private _session: SessionService,
    private _commonServices: CommonServices,
    private questionsPreviewService: QuestionsPreviewService,
    // private livesurvey: LiveSurveyComponent,
    public translate: TranslateService,
    // private liveSurveyComponent: LiveSurveyComponent
  ) {
  }

  ngOnInit() {
    console.log('##', this.questionDetails);
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.responseSingleSelectColumn(el)
          // this.optionSelected[el['selected_row']] = true;
        } else {
          this.mapResponse();
        }
      });
      this.dataEmitter();
      this.totalAutoSum();
    } else {
      this.createResponseJson();
    }
  }

  dataEmitter() {
    this.emitData.emit(this.response);
  }

  saveNumberJson(i, value, optionId, specify, eachTd, eachTr, rowVal, field) {
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    eachTd['columnName'] = selected_header;
    eachTd['rowName'] = rowVal;
    eachTd['invalid'] = false;
    this.deselectColumnRadio(selected_header);
    this.optionSelected = [];
    if (specify) {
      // const specifyInd = this.response.findIndex((ele) => ele['selected_row'] == this.questionDetails['other_specify_index'] || [this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(ele['selected_item'].toLowerCase()) > -1);
      const specifyInd = this.response.findIndex((ele) => ele['selected_row'] == this.questionDetails['other_specify_index'] || ele['selected_item'].match(this.questionsPreviewService.pleaseSpecifyMatch));
      if (specifyInd > -1 && this.specifyText) {
        this.response[specifyInd]['other_specify'] = true;
        this.response[specifyInd]['otherSpecify_value'] = this.specifyText;
      } else {
        if (specifyInd !== -1) {
          delete this.response[specifyInd]['otherSpecify_value'];
        }
      }
      this.dataEmitter();
      return;
    }
    if (value && isNaN(value)) {
      value = this.questionsPreviewService.getNumberFromSurveyLanguage(value);
      console.log('saveNumberJson: translated value: ' + value);
      if (value && isNaN(value)) {
        const temp = this.questionsPreviewService.getLanguageNotificationError();
        this.questionErrror.emit(temp['PlsEnterNumeric']);
        return;
      } else {
        value = +value;
      }
    } else {
      if (value !== '') {
        value = +value;
      }
    }

    if (value < 0) {
      eachTd['number'] = '';
      const temp = this.questionsPreviewService.getLanguageNotificationError();
      this.questionErrror.emit(temp['NegativeNotAllowed']);
    }

    if (value) {
      const val = +value;
      const precision = this._session.api.local.get(Config.CONSTANTS.PRECISION);
      const Field_Precision = this._session.api.local.get('Field_precision');
      let itemPrecisionVal = this.countDecimals(val);
      if (Field_Precision && Field_Precision.length > 0) {
        Field_Precision.forEach(element => {
          if (element.column == eachTd['columnName'] && element.row == eachTd['rowName']) {
            eachTd['field_precision'] = element.precision;
            const rowHeader = this.response.filter(el => el.selected_header == element.column && el.selected_row == element.row)
            if (itemPrecisionVal > element.precision) {
              eachTd['invalid'] = true;
              eachTd['number'] = null;
              this.response.forEach(el => {
                if (el.selected_header === element.column && el.selected_row === element.row) {
                  el['selected_value'] = null;
                }
              });
              this.questionErrror.emit(element.errMsg + ' for ' + rowHeader[0].selected_item);
            }
          } else if (eachTd['field_precision'] == undefined || null) {
            if (itemPrecisionVal > precision) {
              eachTd['invalid'] = true;
              eachTd['number'] = '';
              const temp = this.questionsPreviewService.getLanguageNotificationError();
              this.questionErrror.emit(temp['PrecisionOutofrange'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(precision));

            }
          }
        });
      } else {
        if (itemPrecisionVal > precision) {
          eachTd['invalid'] = true;
          eachTd['number'] = '';
          const temp = this.questionsPreviewService.getLanguageNotificationError();
          this.questionErrror.emit(temp['PrecisionOutofrange'] + this.questionsPreviewService.ConvertNumbersintoSurveyLanguage(precision));
        }
      }
      // tslint:disable-next-line: prefer-const
    }

    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }

    /** If a row is already there update in the row else add new row */
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
      /** Other specify add 2 extra keys */
      if (this.response[Ind]['selected_row'] == this.questionDetails['other_specify_index'] && (value || value == 0)) {
        this.response[Ind]['other_specify'] = true;
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
      } else {
        this.specifyText = '';
        delete this.response[Ind]['otherSpecify_value'];
        delete this.response[Ind]['other_specify'];
      }
    } else{
      this.response.push({ selected_value: value, selected_item: eachTr[1]['value'], selected_header: eachTd['columnName'], selected_row: eachTr[0]['value'], option_type: eachTd['value'], disable: eachTd['disable'] ? eachTd['disable'] : false })
    }
    this.dataEmitter();
  }

  /**
  * To create response object for all the existing rows in live survey
  */
  createResponseJson(type?) {
    this.response = []; let disable = false;
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      let inlinePipe;
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        inlinePipe = false;
        if (col['columnId']) {
          row[colInd]['header'] = col['columnId'];
          if (type !== 'none') {
            row[colInd]['number'] = row[colInd]['number'] ? row[colInd]['number'] : '';
            disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
            if (row[colInd]['parseStatus'] && row[colInd]['parseStatus'] == 'Success' && row[colInd]['parsedInstruction'].includes('pipe')) {
              row[colInd]['number'] = Number(row[colInd]['value']);
              inlinePipe = true;
            }
          } else {
            row[colInd]['number'] = row[colInd]['value'] == 'Single choice' ? false : '';
          }
          this.response.push({ selected_value: row[colInd]['number'], selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: inlinePipe ? 'Number' : row[colInd]['value'], disable: disable })
        }
      });
    });
    this.totalAutoSum();
    this.dataEmitter();
  }

  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['number'] = resp['selected_value'];
          row[colInd]['header'] = resp['selected_header'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
        if(row[0]['value'] == resp['selected_row']){
          resp['disable'] = row[colInd]['disable'] ? row[colInd]['disable'] : false;
        }
      });
    });
  }

  chooseNone(rowIndex, OptionId, colId, eachTd) {

    if (eachTd.valueType === 'Label' || eachTd.value !== 'Single choice' || eachTd.disable) { return }

    /** Radio button will select While clicking the td */
    this.questionDetails.Options.rows.forEach((resp, index) => {
      if (OptionId == resp[0]['value']) {
        resp[colId]['radioSelect'] = true;
      } else {
        resp[colId]['radioSelect'] = false;
      }
    });

    // this.optionSelected = [];
    // this.createResponseJson('none');
    const selected_header = this.questionDetails.Options.columns[colId]['columnId'];
    this.deselectColumn(selected_header);
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    console.log(this.optionSelected);
    this.dataEmitter();
    this.optionSelected[colId] = true;
  }


  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }


  calculateAutoSum(index, row, currentIndex) {
    let sum = 0;
    row.forEach(el => {
      if (el.value === 'Number' && el.number != null) {
        sum += Number(el.number);
      }
      if (el.value === '' && el.instruction === '[AUTOSUM]') {
        el.number = Number(sum);
      }
    });
    // if (index === 4) {
    let columnSum = 0;
    const columLen = this.questionDetails.Options.rows.length;
    this.questionDetails.Options.rows.forEach(element => {
      // element[currentIndex]
      if (element[currentIndex].value === 'Number' && element[currentIndex].number != null) {
        columnSum += Number(element[currentIndex]['number']);
      }
    });
    if (this.questionDetails.Options.rows[columLen - 1] && this.questionDetails.Options.rows[columLen - 1][currentIndex] && this.questionDetails.Options.rows[columLen - 1][currentIndex]['instruction'] === '[AUTOSUM]') {
      this.questionDetails.Options.rows[columLen - 1][currentIndex]['number'] = Number(columnSum);
    }
  }

  totalAutoSum() {
    let rowsum = 0;
    let colSum = 0;
    this.questionDetails.Options.rows.forEach((el, index) => {
      el.forEach(innerEl => {
        if ((innerEl.value === 'Number' && innerEl.number != null) || innerEl.parseStatus == 'Success' && innerEl.parsedInstruction.includes('pipe')) {
          let num = 0;
          if(innerEl.parsedInstruction && innerEl.parsedInstruction.includes('pipe')){
            num = innerEl.value;
          }else{
            num = innerEl.number;
          }
          rowsum += Number(num);
        }
        if (innerEl.instruction === '[AUTOSUM]') {
          innerEl['number'] = Number(rowsum);
          rowsum = 0;
        }
      });
    });

    const columLen = this.questionDetails.Options.rows.length;
    this.questionDetails.Options.rows[columLen - 1].forEach((element, i) => {
      if (element && element.instruction === '[AUTOSUM]') {
        this.questionDetails.Options.rows.forEach(ele => {
          ele.forEach((arr, innerIndex) => {
            if (i === innerIndex && ((arr.value === 'Number') || arr.parseStatus == 'Success' && arr.parsedInstruction.includes('pipe') )) {
              let num = 0;
              if (arr.parsedInstruction && arr.parsedInstruction.includes('pipe')) {
                num = arr.value;
              } else {
                num = arr.number;
              }
              colSum += Number(num);
            }
          });
        });
        element['number'] = Number(colSum) ? Number(colSum) : element['number'];
        colSum = 0;
      }
    });
    console.log(this.questionDetails);

  }

  countDecimals(value) {
    if (Math.floor(value) === value) { return 0; }
    return value.toString().split('.')[1].length || 0;
  }


  deselectColumn(selected_header) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] == selected_header) {
          row[colInd]['number'] = '';
        }
      });
    });
    this.response.forEach(element => {
      if (element && element.selected_header == selected_header) {
        element.selected_value = '';
      }
    });
    console.log(this.optionSelected);

    this.dataEmitter();
  }

  deselectColumnRadio(selected_header) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] === selected_header) {
          if (row[colInd]['header'] == selected_header && row[colInd]['value'] == 'Single choice') {
            row[colInd]['radioSelect'] = false;
          }
        }
      });
    });
    this.response.forEach(element => {
      if (element && element.selected_header == selected_header && element.option_type == 'Single choice') {
        element.selected_value = 0;
      }
    });
  }

  responseSingleSelectColumn(element) {
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      // const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId'] === element['selected_header'] && row[0]['value'] === element.selected_row) {
          row[colInd]['radioSelect'] = true;
        }
      });
    });
  }

}
