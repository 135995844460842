import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { CommonServices } from 'src/app/services/common-services.service';
import { QuestionsPreviewService } from '../questions-preview.service';

@Component({
  selector: 'app-open-ended-text-question',
  templateUrl: './open-ended-text-question.component.html',
  styleUrls: ['./open-ended-text-question.component.css']
})
export class OpenEndedTextQuestionComponent implements OnInit {
  @Input() questionDetails: any;
  @Output('onDataEmit') emitData = new EventEmitter();
  public response = [];
  public optionSelected = [];
  public OTHER_OPTION = "Other (please specify)".toLowerCase();
  public OTHERS_OPTION = "Others (please specify)".toLowerCase();
  public specifyText = '';

  constructor(
    private _commonServices: CommonServices,
    private commonQstnService: QuestionsPreviewService
  ) { }

  ngOnInit() {
    if (this.questionDetails['response'] && this.questionDetails['response'].length > 0) {
      this.response = this.questionDetails['response'];
       
      this.dataEmitter();
      /** check if user has selected none of the above (single select option)*/
      this.response.forEach(el => {
        if (el['option_type'] == 'Single choice' && el['selected_value'] == 1) {
          this.optionSelected[el['selected_header']] = true;
          this.questionDetails['columns'].forEach((elm,ind) => {
            if (elm.columnId == el['selected_header']) {
              this.questionDetails['rows'].forEach((rowData,index) => {
                if (rowData[0].value = el['selected_row']) {
                  rowData[ind]['radioSelect'] = true;
                } else{
                  rowData[ind]['radioSelect'] = false;
                }
              });
            }
          });
        } else {
          this.mapResponse();
        }
      });
    } else {
      this.createResponseJson();
    }
  }

  dataEmitter() {
    this.emitData.emit(this.response);
  }

  /**
   * Triggered on change of text entered
   * @param optionId row index
   * @param i - tr index
   * @param value -  value entered
   */
  saveText(optionId, i, value, specify) {
    if (specify) {
      // const specifyInd = this.response.findIndex((ele) => [this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(ele['selected_item']) > -1 || ele['selected_row'] == this.questionDetails['other_specify_index']);
      const specifyInd = this.response.findIndex((ele) => ele['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) || ele['selected_row'] == this.questionDetails['other_specify_index']);
      if (specifyInd > -1 && this.specifyText) {
        this.response[specifyInd]['otherSpecify_value'] = this.specifyText;
        this.response[specifyInd]['other_specify'] = true;

      } else {
        delete this.response[specifyInd]['otherSpecify_value'];
        delete this.response[specifyInd]['other_specify'];

      }
      return;
    }
    /** Remove no from response */
    const noInd = this.response.findIndex((ele) => ele['option_type'] === 'Single choice' && ele['selected_value']);
    if (noInd > -1) {
      this.response[noInd]['selected_value'] = '';
    }
    const selected_header = this.questionDetails.Options.columns[i]['columnId'];
    const Ind = this.response.findIndex((ele) => ele['selected_row'] == optionId && ele['selected_header'] == selected_header);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = value;
      /** Other specify add 2 extra keys */
      // if ([this.OTHER_OPTION, this.OTHERS_OPTION].indexOf(this.response[Ind]['selected_item']) > -1 || this.response[Ind]['selected_row'] == this.questionDetails['other_specify_index']) {
      if (this.response[Ind]['selected_item'].match(this.commonQstnService.pleaseSpecifyMatch) || this.response[Ind]['selected_row'] == this.questionDetails['other_specify_index']) {
        this.response[Ind]['other_specify'] = this.specifyText ? true : false;
        this.response[Ind]['otherSpecify_value'] = this.specifyText;
      } else {
        this.specifyText = '';
        delete this.response[Ind]['otherSpecify_value'];
        delete this.response[Ind]['other_specify'];
      }
    }
    this.dataEmitter();
    this.optionSelected = [];
  }

  mapResponse() {
    this.questionDetails['response'].forEach((resp, respInd) => {
      /**check  if all rows are presnt in response else remove it*/
      const rowInd = this.questionDetails.Options.rows.findIndex((ele) => ele[0]['value'] == resp['selected_row']);
      if (rowInd <= -1) {
        this.questionDetails['response'].splice(respInd, 1);
      }
      const colInd = this.questionDetails.Options.columns.findIndex((ele) => ele['columnId'] == resp['selected_header']);
      this.questionDetails.Options.rows.forEach((row, rowInd) => {
        if (colInd > -1 && row[0]['value'] == resp['selected_row']) {
          row[colInd]['enteredText'] = resp['selected_value'];
        }
        if (resp['other_specify'] && resp['otherSpecify_value']) {
          this.specifyText = resp['otherSpecify_value'];
        }
      });
    });
  }
  chooseNone(rowIndex, OptionId, eachTd, colIndex) {

    if(eachTd.valueType === 'Label' || eachTd.value !== 'Single choice' || eachTd.disable) { return }

    this.optionSelected = [];
    this.createResponseJson('none');
    const Ind = this.response.findIndex((ele) => ele['option_type'] == 'Single choice' && ele['selected_row'] == OptionId);
    if (Ind > -1) {
      this.response[Ind]['selected_value'] = 1;
    }
    this.dataEmitter();
    this.optionSelected[OptionId] = true;

    this.questionDetails.Options.rows.forEach((resp, index) => {
      if (OptionId == resp[0]['value'] && resp[colIndex]['value'] == 'Single choice') {
        resp[colIndex]['radioSelect'] = true;
      } else {
        resp[colIndex]['radioSelect'] = false;
      }
    });
  }

  /**
   * To create response object for all the existing rows in live survey
   */
  createResponseJson(type?) {
    this.response = []; let disable = false;
    this.questionDetails.Options.rows.forEach((row, rowInd) => {
      const selectedItem = this.findSelectedItemIndex(row);
      this.questionDetails.Options.columns.forEach((col, colInd) => {
        if (col['columnId']) {
          if (type !== 'none') {
            row[colInd]['enteredText'] = row[colInd]['enteredText'] ? row[colInd]['enteredText'] : '';
            disable = row[colInd]['disable'] ? row[colInd]['disable'] : false;
          } else {
            row[colInd]['enteredText'] = row[colInd]['value'] == 'Single choice' ? false : '';
          }
          this.response.push({ selected_value: row[colInd]['enteredText'], selected_item: selectedItem, selected_header: col['columnId'], selected_row: row[0]['value'], option_type: row[colInd]['value'], disable: disable })
        }
      });
    });
    this.dataEmitter();
  }

  /**
   * To find selected item
   * @param row - row 
   * @returns - selected item value
   */
  findSelectedItemIndex(row) {
    const Ind = row.findIndex((ele, Ind) => ele['valueType'] == 'Label' && Ind != 0);
    if (Ind > - 1) {
      return row[Ind]['value'];
    } else {
      return '';
    }
  }
}
